import React from 'react';
import '../../App.css';
import {useStudentConsultFormState} from '../../hooks/useStudentConsultFormState';

export const StudentConsultComplete = () => {
  const {studentConsultForm} = useStudentConsultFormState();

  return (
    <>
      <div className="uk-flex-top" id="modal-submit" uk-modal="true">
        <div className="uk-modal-dialog uk-margin-auto-vertical uk-modal-body">
          {studentConsultForm.submitError === '1' ? (
            <div>
              <h4 className="uk-modal-title">エラー</h4>
              <p>送信に失敗しました。エラーの内容を確認して、もう一度送信してください。</p>
              <p className="uk-text-center">
                <button id="modal-submit-close" className="uk-button uk-button-default uk-modal-close" type="button">とじる</button>
              </p>
            </div>
          ) : (
            <div>
              <h4 className="uk-modal-title">相談を送信しました</h4>
              <p>
                ありがとうございます。送信された相談の内容を確認して対応します。
              </p>
              <p className="uk-text-center">
                <button id="modal-submit-close" className="uk-button uk-button-default uk-modal-close" type="button">とじる</button>
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
