import React from 'react';
import '../../App.css';
import {useUserState} from '../../hooks/useUserState';

export const UserInfo = () => {
  const {user} = useUserState();

  return (
    <>
      <div className="uk-flex-top" id="modal-userInformation" uk-modal="true">
        <div className="uk-modal-dialog uk-margin-auto-vertical">
          <div className="uk-modal-header">
            <h3 className="uk-text-bold uk-text-center uk-width-1-1">ユーザ情報</h3>
          </div>
          <div className="uk-modal-body uk-height-1-1">
            <div className="uk-width-1-1 uk-height-1-1 uk-margin-remove">
              <div className="uk-height-1-1 uk-padding-remove">
                <form className="uk-width-1-1 uk-form-horizontal">
                  <div className="uk-margin-bottom">
                    <div className="uk-form-label">メールアドレス</div>
                    <div className="uk-form-controls">
                      <input className="uk-input uk-form-blank" type="text" defaultValue={user.email} />
                    </div>
                  </div>
                  <div className="uk-margin-bottom">
                    <div className="uk-form-label">組織</div>
                    <div className="uk-form-controls">
                      <input className="uk-input uk-form-blank" type="text" defaultValue={user.area} />
                    </div>
                  </div>
                  <div className="uk-margin-bottom">
                    <div className="uk-form-label">所属</div>
                    <div className="uk-form-controls">
                      <input className="uk-input uk-form-blank" type="text" defaultValue={user.school === 'not student' ? '教育委員会' : user.school} />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <button className="uk-modal-close-default" type="button" uk-close="true"></button>
        </div>
      </div>
    </>
  );
};
