import React from 'react';
import '../../App.css';

export const IssueDetailUpdateComplete = ({issue}) => {
  return (
    <>
      <div className="uk-flex-top" id="modal-incident-submit" uk-modal="true">
        <div className="uk-modal-dialog uk-margin-auto-vertical uk-modal-body">
          <div>
            <p className="uk-text-center">情報を更新しました。</p>
            <p className="uk-text-center">
              <button className="uk-button uk-button-default uk-modal-close" type="button">閉じる</button>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
