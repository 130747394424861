import React, {useEffect, useState} from 'react';
import '../../App.css';
import toptitle from '../../images/toptitle.png';

const StudentExpirePage = () => {  
  return (
    <div className="wrapper background-image-display">
      <div className='uk-width-1-1 uk-flex uk-flex-middle'>
        <div className='uk-container uk-animation-fade'>
        <img className='uk-align-center uk-width-small' src={toptitle} alt="title" width="300" />
          マモレポの試験期間は終わりました。<br />再開するときは先生からまた連絡があります
        </div>
      </div>
    </div>
  );
};

export default StudentExpirePage;
