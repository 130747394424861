import React, {useEffect, useState} from 'react';
import '../App.css';
import {useUserState} from '../hooks/useUserState';
import {useIssuesState} from '../hooks/useIssuesState';
import {useLoadingState} from '../hooks/useLoadingState';
import {IssueCommunicate} from '../components/modal/issueCommunicate';
import {IssueDetail} from '../components/modal/issueDetail';
import {Pagination} from '../components/common/pagination';
import moment from 'moment';

export const IssuesList = () => {
  const {setLoading} = useLoadingState();
  const {user} = useUserState();
  const {issues, setIssues, fetchIssues} = useIssuesState();
  const [selectedIssue, setSelectedIssue] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const getInitialData = async () => {
      setLoading(true);
      await fetchIssues();
      setLoading(false);
    };

    getInitialData();
  }, []);

  const handleChange = (event) => {
    setIssues({
      ...issues,
      [event.target.name]: event.target.value
    });
  }

  const handleSearch = async () => {
    const res = await fetchIssues(false);
    let issuesData = res['issues'];

    // 所属での絞り込み
    if (issues.searchSchool !== '') {
      issuesData = issuesData.filter((issue) => issue.created_school === issues.searchSchool)
    }

    // ステータスでの絞り込み
    if (issues.searchStatus === '1') {
      issuesData = issuesData.filter((issue) => issue.status !== 2)
    }

    setIssues({
      ...issues,
      data: issuesData
    });
    setCurrentPage(1)
  }

  const handleSelectIssue = (issue) => {
    setSelectedIssue(issue)
  }

  const handleSelectStudent = (issue) => {
    setSelectedIssue(issue)
  }

  const displayData = () => {
    const displayData = [];
    const startIndex = (currentPage - 1) * 10;
    for(let i = startIndex; i < startIndex + 10; i++) {
      if (issues.data[i] !== undefined) {
        displayData.push(issues.data[i]);
      }
    }
    return displayData;
  }

  const handleNext = () => {
    setCurrentPage(currentPage + 1)
  }

  const handlePrevious = () => {
    setCurrentPage(currentPage - 1)
  }

  return (
    <>
      <div className='uk-width-1-1 uk-position-top uk-container uk-animation-fade'>
        <h4 className="uk-text-left uk-margin-large-top">相談一覧</h4>
        <div className="uk-width-1-1 uk-margin-remove-left uk-margin-remove-right" uk-grid="true">
          <div id="studentSchoolDiv" className="uk-padding-remove-left">
            <input id="searchStudentSchool" className="uk-input uk-width-medium" type="text" name="searchSchool" value={issues.searchSchool} onChange={handleChange} placeholder="所属名"/>
          </div>
          <div id="studentClosedDiv">
            <select id="searchStudentClosed" className="uk-select uk-width-medium" name="searchStatus" value={issues.searchStatus} onChange={handleChange}>
              <option value="">すべて</option>
              <option value="1">未対応・対応中</option>
            </select>
          </div>
          <div>
            <button className="uk-button uk-button-primary" onClick={handleSearch}>検索</button>
          </div>
        </div>
        <table className="uk-table uk-table-striped uk-width-1-1 uk-table-small uk-table-hover">
          <thead>
            <tr>
              <th>組織</th>
              <th>所属</th>
              <th>相談者が入力した名前</th>
              <th>相談内容</th>
              <th>相談日時</th>
              <th>対応状況</th>
            </tr>
          </thead>
          <tbody>
            {displayData().map((issue, i) => (
              <tr key={`issue-list-${i}`}>
                <td>{issue.created_area}</td>
                <td>{issue.created_school}</td>
                {issue.anonymous ? (
                    <td id={i}>匿名</td>
                  ) : (
                    <td id={i} onClick={() => handleSelectStudent(issue)}>
                      {user.ucl !== 'eb' ? (
                          <a href="#" uk-toggle="target: #modal-communicate">{issue.created_name}</a>
                        ) : issue.created_name
                      }
                    </td>
                )}
                <td id={i} onClick={() => handleSelectIssue(issue)}>
                  <a href="#" uk-toggle="target: #modal-detail">
                    {issue.overview}
                  </a>
                </td>
                <td>{moment(issue.created_at).format('YYYY/MM/DD HH:mm')}</td>
                <td><span className={String(issue.status) === '0' ? 'uk-label uk-label-danger' : String(issue.status) === '1' ? 'uk-label uk-label-success' : 'uk-label'}>{String(issue.status) === '0' ? '未対応' : String(issue.status) === '1' ? '対応中' : '対応済'}</span></td>
              </tr>
            ))}
          </tbody>
        </table>

        <Pagination data={issues.data} handleNext={handleNext} handlePrevious={handlePrevious} currentPage={currentPage} />
        
        <button className="uk-button uk-hidden" id="modal-error" type="button" uk-toggle="target: #modal-error"></button>
      </div>
      <IssueDetail issue={selectedIssue} />
      <IssueCommunicate selectedStudentId={selectedIssue?.owner_id} />
    </>
  );
};
