import React, {useEffect} from 'react';
import '../../App.css';
import {useUserRegisterFormState} from '../../hooks/useUserRegisterFormState';
import {useUsersState} from '../../hooks/useUsersState';
import {useUserState} from '../../hooks/useUserState';

export const UserRegister = ({selectedUser}) => {
  const {userRegisterForm, setUserRegisterForm, postUserRegisterForm} = useUserRegisterFormState();
  const {fetchUsers} = useUsersState();
  const {user} = useUserState();

  useEffect(() => {
    setUserRegisterForm({
      ...userRegisterForm,
      area: user.area
    })
  }, [user]);

  const handleOnChange = (event) => {
    setUserRegisterForm({
      ...userRegisterForm,
      [event.target.name]: event.target.value
    })
  }

  const handleRegisterUserInformation = async (event) => {
    if (userRegisterForm.area === '') {
      setUserRegisterForm({
        ...userRegisterForm,
        errorMessage: 'エラー: 組織を入力してください'
      });
      return;
    } else if (userRegisterForm.email === '') {
      setUserRegisterForm({
        ...userRegisterForm,
        errorMessage: 'エラー: メールアドレスを入力してください'
      });
      return;
    } else if (userRegisterForm.password === '') {
      setUserRegisterForm({
        ...userRegisterForm,
        errorMessage: 'エラー: パスワードを入力してください'
      });
      return;
    } else if (userRegisterForm.role === '') {
      setUserRegisterForm({
        ...userRegisterForm,
        errorMessage: 'エラー: ロールを選択してください'
      });
      return;
    }

    const res = await postUserRegisterForm()
    if (!res) {
      setUserRegisterForm({
        ...userRegisterForm,
        errorMessage: 'エラー: 登録に失敗しました。'
      });
      return;
    }

    setUserRegisterForm({
      ...userRegisterForm,
      errorMessage: ''
    });

    await fetchUsers();
    alert('登録が完了しました。');
    document.getElementById('userRegisterClose').click();
  }

  return (
    <>
      <div className="uk-flex-top" id="modal-userRegister" uk-modal="true">
        <div className="uk-modal-dialog uk-margin-auto-vertical">
          <div className="uk-modal-header">
            <h3 className="uk-text-bold uk-text-center uk-width-1-1">ユーザ登録</h3>
          </div>
          <div className={userRegisterForm.errorMessage ? 'uk-alert-danger uk-margin-top uk-margin-remove-bottom uk-align-center uk-padding-small' : 'uk-hidden'} uk-alert="true">
            <p className="uk-margin-remove">{userRegisterForm.errorMessage}</p>
          </div>
          <div className="uk-modal-body uk-height-1-1">
            <div className="uk-width-1-1 uk-height-1-1 uk-margin-remove">
              <div className="uk-height-1-1 uk-padding-remove">
                <form className="uk-width-1-1 uk-form-horizontal">
                  <div className="uk-margin-bottom">
                    <div className="uk-form-label">組織</div>
                    <div className="uk-form-controls">
                      <input className="uk-input" id="registerUserArea" type="text" value={userRegisterForm.area} name="area" onChange={handleOnChange} disabled />
                    </div>
                  </div>
                  <div className="uk-margin-bottom">
                    <div className="uk-form-label">所属</div>
                    <div className="uk-form-controls">
                      <input className="uk-input" id="registerUserSchool" type="text" value={userRegisterForm.school} name="school" onChange={handleOnChange} />
                    </div>
                  </div>
                  <div className="uk-margin-bottom">
                    <div className="uk-form-label">メールアドレス</div>
                    <div className="uk-form-controls">
                      <input className="uk-input" id="registerUserName" type="text" value={userRegisterForm.email.trim()} name="email" onChange={handleOnChange} />
                    </div>
                  </div>
                  <div className="uk-margin-bottom">
                    <div className="uk-form-label">パスワード</div>
                    <div className="uk-form-controls">
                      <input className="uk-input" id="registerUserPassword" type="text" value={userRegisterForm.password.trim()} name="password" onChange={handleOnChange} />
                    </div>
                  </div>
                  <div className="uk-margin-bottom">
                    <div className="uk-form-label">ロール</div>
                    <div className="uk-form-controls">
                      <select className="uk-select" id="registerUserRole" value={userRegisterForm.role} name="role" onChange={handleOnChange} >
                        <option value=""></option>
                        <option value="student">生徒</option>
                        <option value="teacher">先生</option>
                        <option value="eb">教育委員会</option>
                      </select>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <p className="uk-text-center uk-margin-top">
              <button className="uk-button uk-button-default uk-margin-small-right" type="button" onClick={handleRegisterUserInformation}>登録</button>
              <button className="uk-button uk-hidden" id="modal-error" type="button" uk-toggle="target: #modal-error"></button>   
            </p>
          </div>
          <button className="uk-modal-close-default" id="userRegisterClose" type="button" uk-close="true"></button>
        </div>
      </div>
    </>
  );
};
