import React, {useEffect} from 'react';
import '../../App.css';
import {StudentNavigationBar} from '../../components/navi/studentNavigationBar';
import {useUserState} from '../../hooks/useUserState';
import {useNavigate} from 'react-router-dom';
import {openDocument} from '../../utils/openDocument';
import {StudentHowTo} from '../../components/modal/studentHowTo';
import {StudentIssueList} from '../../components/modal/studentIssueList';
import {StudentSendMessage} from '../../components/modal/studentSendMessage';
import {useIssuesState} from '../../hooks/useIssuesState';
import {useMessagesState} from '../../hooks/useMessagesState';
import {useStudentConsultFormState} from '../../hooks/useStudentConsultFormState';

import usage1_image from '../../images/usage1.jpg';
import usage2_image from '../../images/usage2.jpg';
import thumbnail_doc0 from '../../images/thumbnail_doc0.png';
import thumbnail_doc1 from '../../images/thumbnail_doc1.png';
import thumbnail_doc2 from '../../images/thumbnail_doc2.png';
import thumbnail_doc3 from '../../images/thumbnail_doc3.png';
import thumbnail_doc4 from '../../images/thumbnail_doc4.png';
import thumbnail_doc5 from '../../images/thumbnail_doc5.png';
import thumbnail_doc6 from '../../images/thumbnail_doc6.png';
import thumbnail_doc7 from '../../images/thumbnail_doc7.png';
import thumbnail_doc8 from '../../images/thumbnail_doc8.png';

const StudentTopPage = () => {
  const navigate = useNavigate();
  const {user} = useUserState();
  const {fetchIssues} = useIssuesState();
  const {fetchMessages} = useMessagesState();
  const {clearStudentConsultForm} = useStudentConsultFormState();

  useEffect(() => {
    if (user.ucl !== 'student') {
      navigate('/')
    }
    if (user.area === "山形県村山市") {
      navigate('/student/expire')
    }
    clearStudentConsultForm()
  }, []);

  const handleOpenDocument = (event) => {
    openDocument(event.currentTarget.id);
  }

  const handleStartConsult = () => {
    navigate('/student/consult/1')
  }

  const handleIssueListOpen = () => {
    fetchIssues();
  }

  const handleMessageOpen = () => {
    fetchMessages();
  }

  return (
    <div className="wrapper">
      <div className='uk-width-1-1 uk-flex uk-flex-middle'>
        <StudentNavigationBar />
        <div className="uk-width-1-1 uk-position-top uk-container uk-animation-fade">
          <div className="uk-width-1-1 uk-margin-large-top" uk-grid="true">
            <div className="uk-width-2-3">
              <h3>マモレポの<ruby><rb>使</rb><rp>(</rp><rt>つか</rt><rp>)</rp></ruby>い<ruby><rb>方</rb><rp>(</rp><rt>かた</rt><rp>)</rp></ruby></h3>
              <div className="uk-child-width-1-1 uk-margin-remove-top uk-margin-small-bottom selectCard" uk-grid="true">
                <img className="uk-margin" width="100" src={usage1_image} />
                <img className="uk-margin" width="100" src={usage2_image} />
              </div>
              {/*<div id="doc0" className="uk-child-width-1-1 uk-margin-remove-top uk-margin-small-bottom selectCard" uk-grid="true">
                <div className="uk-margin-bottom">
                  <div className="uk-card uk-card-default uk-grid-collapse uk-flex-middle" uk-grid="true">
                    <div className="uk-card-media-left uk-width-1-4 uk-text-center">
                      <img className="uk-margin" width="100" src={thumbnail_doc0} />
                    </div>
                    <div className="uk-card-body uk-width-expand uk-padding-small">
                      <h4 className="uk-margin-small uk-text-bold uk-flex-middle"><ruby><rb>相談</rb><rp>(</rp><rt>そうだん</rt><rp>)</rp></ruby>したいとき</h4>
                      <p className="uk-text-small uk-text-muted">マモレポの<ruby><rb>使</rb><rp>(</rp><rt>つか</rt><rp>)</rp></ruby>い<ruby><rb>方</rb><rp>(</rp><rt>かた</rt><rp>)</rp></ruby>を<ruby><rb>動画</rb><rp>(</rp><rt>どうが</rt><rp>)</rp></ruby>で<ruby><rb>紹介</rb><rp>(</rp><rt>しょうかい</rt><rp>)</rp></ruby>しています。</p>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="uk-margin-remove-top"/>
              <h3><ruby><rb>読</rb><rp>(</rp><rt>よ</rt><rp>)</rp></ruby>んでみよう</h3>
              <div id="doc1" className="uk-child-width-1-1 uk-margin-remove-top uk-margin-small-bottom selectCard" uk-grid="true">
                <div className="uk-margin-bottom">
                  <div className="uk-card uk-card-default uk-grid-collapse uk-flex-middle" uk-grid="true">
                    <div className="uk-card-media-left uk-width-1-4 uk-text-center">
                      <img className="uk-width-small" src={thumbnail_doc6} />
                    </div>
                    <div className="uk-card-body uk-width-expand uk-padding-small">
                      <h4 className="uk-margin-small uk-text-bold">いじめってどんなこと？</h4>
                      <p className="uk-text-small uk-text-muted">集団での学習や生活を通して・・・・</p>
                    </div>
                  </div>
                </div>
              </div>
              <div id="doc1" className="uk-child-width-1-1 uk-margin-remove-top uk-margin-small-bottom selectCard" uk-grid="true">
                <div className="uk-margin-bottom">
                  <div className="uk-card uk-card-default uk-grid-collapse uk-flex-middle" uk-grid="true">
                    <div className="uk-card-media-left uk-width-1-4 uk-text-center">
                      <img className="uk-width-small" src={thumbnail_doc7} />
                    </div>
                    <div className="uk-card-body uk-width-expand uk-padding-small">
                      <h4 className="uk-margin-small uk-text-bold">学校に行きたくないなって感じたら</h4>
                      <p className="uk-text-small uk-text-muted">朝起きるのがつらい、学校に行きたくないと思ったらどうすればいいのでしょうか・・・・・・・</p>
                    </div>
                  </div>
                </div>
              </div>
              <div id="doc1" className="uk-child-width-1-1 uk-margin-remove-top uk-margin-small-bottom selectCard" uk-grid="true">
                <div className="uk-margin-bottom">
                  <div className="uk-card uk-card-default uk-grid-collapse uk-flex-middle" uk-grid="true">
                    <div className="uk-card-media-left uk-width-1-4 uk-text-center">
                      <img className="uk-width-small" src={thumbnail_doc8} />
                    </div>
                    <div className="uk-card-body uk-width-expand uk-padding-small">
                      <h4 className="uk-margin-small uk-text-bold">SNSとの上手な付き合い方</h4>
                      <p className="uk-text-small uk-text-muted">皆さんもSNSから情報を得ることが増えてきているのではないでしょうか？・・・・・・・・・</p>
                    </div>
                  </div>
                </div>
              </div>*/}
            </div>
            <div className="uk-width-1-3">
              <h3>　</h3>
              <button className="uk-button uk-button-primary uk-width-large uk-margin-remove-top uk-padding-remove-left uk-padding-remove-right uk-align-center" onClick={handleStartConsult}>
                <div  className="uk-flex-middle uk-padding-small" uk-grid="true">
                  <div className="uk-width-1-5">
                    <span uk-icon="icon: mail; ratio: 2"></span>
                  </div>
                  <div className="uk-width-expand uk-padding-remove uk-margin-small-left">
                    <p className="uk-align-left uk-text-large uk-margin-remove-right">相談をする</p>
                  </div>
                </div>
              </button>
              <button className="uk-button uk-button-primary uk-width-large uk-padding-remove-left uk-padding-remove-right uk-align-center" onClick={handleIssueListOpen} uk-toggle="target: #modal-issuelist">
                <div  className="uk-flex-middle uk-padding-small" uk-grid="true">
                  <div className="uk-width-1-5">
                    <span uk-icon="icon: list; ratio: 2"></span>
                  </div>
                  <div className="uk-width-expand uk-padding-remove uk-margin-small-left">
                    <p className="uk-align-left uk-text-large uk-margin-remove-right">相談の記録を見る</p>
                  </div>
                </div>
              </button>
              {/*<button className="uk-button uk-button-text uk-margin-large-bottom uk-align-left" uk-toggle="target: #modal-message" onClick={handleMessageOpen}>教育委員会に相談する</button>*/}
            </div>
          </div>
        </div>
      </div>
      <StudentHowTo />
      <StudentIssueList />
      <StudentSendMessage />
    </div>
  );
};

export default StudentTopPage;
