import React from 'react';
import '../../App.css';

export const PasswordSecureQuestionForgotComplete = () => {
  return (
    <>
      <div className="uk-flex-top" id="modal-pwResetDone" uk-modal="true">
        <div className="uk-modal-dialog uk-margin-auto-vertical uk-modal-body">
          <p className="uk-text-center">
            パスワードのリセットをお<ruby><rb>願</rb><rp>(</rp><rt>ねが</rt><rp>)</rp></ruby>いしました。
            <br />
            <ruby><rb>先生</rb><rp>(</rp><rt>せんせい</rt><rp>)</rp></ruby>からの<ruby><rb>連絡</rb><rp>(</rp><rt>れんらく</rt><rp>)</rp></ruby>を<ruby><rb>待</rb><rp>(</rp><rt>ま</rt><rp>)</rp></ruby>っていてください。
          </p>
          <p className="uk-text-center">
            <button className="uk-button uk-button-default uk-modal-close" type="button">閉じる</button>
          </p>
        </div>
      </div>
    </>
  );
};
