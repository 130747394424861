import React from 'react';
import '../../App.css';
import toptitle from '../../images/toptitle.png';
import {useUserState} from '../../hooks/useUserState';
import {useNavigate} from 'react-router-dom';
import {UserInfo} from '../../components/modal/userInfo';

export const EbNavigationBar = () => {
  const navigate = useNavigate();
  const {user, logout} = useUserState();

  const handleToIssues = (event) => {
    navigate('/eb/issues')
  }

  const handleToMessages = (event) => {
    navigate('/eb/messages')
  }

  const handleToUsers = (event) => {
    navigate('/eb/users')
  }

  const handleToPWChange = () => {
    navigate('/password_change')
  }

  const handleLogout = (event) => {
    logout()
  }

  return (
    <>
      <div id="navBarAdmin" className='fixed uk-width-1-1 uk-animation-fade navbar'>
        <nav className="uk-navbar-container uk-padding-small" uk-navbar="true">
          <div className="uk-navbar-left">
            <ul className="uk-navbar-nav">
              <li><img className="uk-margin-left uk-margin-right" src={toptitle} width="100px" alt="" /></li>
              <li><button className="uk-button uk-button-text uk-margin-right uk-margin-left" onClick={handleToIssues}>相談一覧</button></li>
              <li><button className="uk-button uk-hidden" id="modal-error" type="button" uk-toggle="target: #modal-error"></button></li>
              <li><button className="uk-button uk-button-text uk-margin-right" onClick={handleToMessages}>メッセージ一覧</button></li>
              <li><button className="uk-button uk-button-text uk-margin-right" onClick={handleToUsers}>ユーザ一覧</button></li>
            </ul>
          </div>
          <div className="uk-navbar-right">
            <ul className="uk-navbar-nav">
              <li>
                {user.area} {user.email}
              </li>
              <li>
                <a href="#" className="uk-padding-remove-right"><span uk-icon="icon: user; ratio: 1.3"></span></a>
                <div className="uk-navbar-dropdown">
                    <ul className="uk-nav uk-navbar-dropdown-nav">
                      <li><a href="#" uk-toggle="target: #modal-userInformation">ユーザ情報</a></li>
                      {/*<li><a href="#" onClick={handleToPWChange}>パスワード変更</a></li>*/}
                      <li><a href="#" onClick={handleLogout}>ログアウト</a></li>
                    </ul>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <UserInfo />
    </>
  );
};
