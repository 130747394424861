import React, {useEffect, useState} from 'react';
import '../App.css';
import {useUsersState} from '../hooks/useUsersState';
import {useLoadingState} from '../hooks/useLoadingState';
import {UserDetail} from '../components/modal/userDetail';
import {UserRegister} from '../components/modal/userRegister';
import {Pagination} from '../components/common/pagination';

export const UsersList = () => {
  const {setLoading} = useLoadingState();
  const {users, fetchUsers} = useUsersState();
  const [selectedUser, setSelectedUser] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const getInitialData = async () => {
      setLoading(true);
      await fetchUsers();
      setLoading(false);
    };

    getInitialData();
  }, []);

  const handleUserSelect = (user) => {
    setSelectedUser(user);
  }

  const handleSearch = () => {
  }

  const displayData = () => {
    const displayData = [];
    const startIndex = (currentPage - 1) * 10;
    for(let i = startIndex; i < startIndex + 10; i++) {
      if (users.data[i] !== undefined) {
        displayData.push(users.data[i]);
      }
    }
    return displayData;
  }

  const handleNext = () => {
    setCurrentPage(currentPage + 1)
  }

  const handlePrevious = () => {
    setCurrentPage(currentPage - 1)
  }

  return (
    <>
      <div className='uk-width-1-1 uk-position-top uk-container uk-animation-fade'>
        <h4 className="uk-text-left uk-margin-large-top">ユーザ一覧</h4>
        <div className="uk-width-1-1 uk-margin-remove-left uk-margin-remove-right" uk-grid="true">
          <div>
            <button className="uk-button uk-button-primary" type="button" uk-toggle="target: #modal-userRegister">新規登録</button>
            <button className="uk-button uk-hidden" id="modal-error" type="button" uk-toggle="target: #modal-error"></button>
          </div>
        </div>
        <table className="uk-table uk-table-striped uk-width-1-1 uk-table-small uk-table-hover">
          <thead>
            <tr>
              <th>ID</th>
              <th>組織</th>
              <th>所属</th>
              <th>メールアドレス</th>
            </tr>
          </thead>
          <tbody>
            {displayData().map((user, i) => (
              <tr key={`users-list-tr-${i}`}>
                <td id={i} onClick={() => handleUserSelect(user)}>
                  <a href="#" uk-toggle="target: #modal-userDetail">{user?.sub.slice(-12)}</a>
                </td>
                <td>{user?.area}</td>
                <td>{user?.school}</td>
                <td>{user?.email}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination data={users.data} handleNext={handleNext} handlePrevious={handlePrevious}  currentPage={currentPage} />
      </div>
      <UserDetail selectedUser={selectedUser} />
      <UserRegister />
    </>
  );
};
