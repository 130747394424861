import React, {useEffect, useState} from 'react';
import '../../App.css';
import {useIssuesState} from '../../hooks/useIssuesState';
import moment from 'moment';

export const StudentIssueList = () => {
  const {issues} = useIssuesState();
  const [selectedIssue, setSelectedIssue] = useState(null);

  const handleSelectIssue = (issue) => {
    setSelectedIssue(issue)
  }

  return (
    <>
      <div className="uk-modal-container uk-flex-top" id="modal-issuelist" uk-modal="true">
        <div className="uk-modal-dialog uk-margin-auto-vertical">
          <div className="uk-modal-header">
            <h3 className="uk-text-bold uk-text-center uk-width-1-1">あなたの<ruby><rb>相談</rb><rp>(</rp><rt>そうだん</rt><rp>)</rp></ruby>の<ruby><rb>記録</rb><rp>(</rp><rt>きろく</rt><rp>)</rp></ruby></h3>
          </div>
          <div className="uk-modal-body uk-height-xlarge">
            <div className="uk-width-1-1 uk-height-1-1 uk-margin-remove" uk-grid="true">
              <div className="uk-width-2-5 uk-height-1-1 uk-padding-remove">
                <div className="uk-panel uk-panel-scrollable uk-height-1-1">
                  <ul className="uk-list uk-list-divider">
                    {issues.data.map((issue, i) => (
                      <li id={i} key={`issue-li-${i}`}>
                        <button className="uk-button uk-button-text" onClick={() => handleSelectIssue(issue)}>
                          {moment(issue.created_at).format('YYYY/MM/DD HH:mm') + ' ' + issue.overview}
                        </button>
                      </li>
                    ))}
                  </ul>     
                </div>                  
              </div>
              {/*
              <div className="uk-height-1-1 uk-width-3-5">
                {selectedIssue === null ? (
                  <div className="uk-tile-muted uk-flex uk-flex-middle uk-align-center uk-height-1-1 uk-width-1-1">
                    {issues.data.length > 0 ? (
                        <h4 className="uk-text-bold uk-text-center uk-width-1-1">
                          <ruby><rb>左</rb><rp>(</rp><rt>ひだり</rt><rp>)</rp></ruby>から<ruby><rb>相談</rb><rp>(</rp><rt>そうだん</rt><rp>)</rp></ruby>を<ruby><rb>選</rb><rp>(</rp><rt>えら</rt><rp>)</rp></ruby>ぶと、
                          ここに<ruby><rb>内容</rb><rp>(</rp><rt>ないよう</rt><rp>)</rp></ruby>が<ruby><rb>表示</rb><rp>(</rp><rt>ひょうじ</rt><rp>)</rp></ruby>されます。
                        </h4>
                      ) : (
                        <h4 className="uk-text-bold uk-text-center uk-width-1-1">
                          まだ<ruby><rb>相談</rb><rp>(</rp><rt>そうだん</rt><rp>)</rp></ruby>をしていません。
                        </h4>
                      )
                    }
                  </div>
                ) : (
                  <div className='uk-panel uk-height-1-1'>
                    <h4 className="uk-text-bold uk-heading-bullet">{moment(selectedIssue.created_at).format('YYYY/MM/DD HH:mm') + ' ' + selectedIssue.overview}</h4>
                    <form className="uk-width-1-1 uk-form-horizontal">
                      <div className="uk-margin-bottom">
                        <div className="uk-form-label">あなたの<ruby><rb>名前</rb><rp>(</rp><rt>なまえ</rt><rp>)</rp></ruby></div>
                        <div className="uk-form-controls">
                          <input className="uk-input" type="text" value={selectedIssue.created_name} /> 
                        </div>
                      </div>
                      <div className="uk-margin-bottom">
                        <div className="uk-form-label">いつおきましたか？</div>
                        <div className="uk-form-controls">
                          <input className="uk-input" type="text" value={selectedIssue.when} />
                        </div>
                      </div>
                      <div className="uk-margin-bottom">
                        <label className="uk-form-label uk-margin-remove-top"><ruby><rb>今</rb><rp>(</rp><rt>いま</rt><rp>)</rp></ruby>も<ruby><rb>続</rb><rp>(</rp><rt>つづ</rt><rp>)</rp></ruby>いている</label>
                        <div className="uk-form-controls">
                          <input id="modal-issuelist_whenSub" className="uk-input" type="text" value={selectedIssue.progress === true ? 'はい' : 'いいえ'}/>
                        </div>
                      </div>
                      <div className="uk-margin-bottom">
                        <div className="uk-form-label"><ruby><rb>誰</rb><rp>(</rp><rt>だれ</rt><rp>)</rp></ruby>のことですか？</div>
                        <div className="uk-form-controls">
                          <input className="uk-input" type="text" value={selectedIssue.target} />
                        </div>
                      </div>
                      <div className="uk-margin-bottom">
                        <div className="uk-form-label"><ruby><rb>何</rb><rp>(</rp><rt>なに</rt><rp>)</rp></ruby>がおきましたか？</div>
                        <div className="uk-form-controls">
                          <input className="uk-input" type="text" value={selectedIssue.overview} />
                        </div>
                      </div>
                      <div className="uk-margin-bottom">
                        <div className="uk-form-label"><ruby><rb>場所</rb><rp>(</rp><rt>ばしょ</rt><rp>)</rp></ruby>はどこですか？</div>
                        <div className="uk-form-controls">
                          <input className="uk-input" type="text" value={selectedIssue.location} />
                        </div>
                      </div>
                      <div className="uk-margin-bottom">
                        <div className="uk-form-label"><ruby><rb>相手</rb><rp>(</rp><rt>あいて</rt><rp>)</rp></ruby>は<ruby><rb>誰</rb><rp>(</rp><rt>だれ</rt><rp>)</rp></ruby>ですか？</div>
                        <div className="uk-form-controls">
                          <input className="uk-input" type="text" value={selectedIssue.opponent} />
                        </div>
                      </div>
                      <div className="uk-margin-bottom">
                        <div className="uk-form-label"><ruby><rb>知</rb><rp>(</rp><rt>し</rt><rp>)</rp></ruby>っている<ruby><rb>人</rb><rp>(</rp><rt>ひと</rt><rp>)</rp></ruby>はいますか？</div>
                        <div className="uk-form-controls">
                          <input className="uk-input" type="text" value={selectedIssue.observer} />
                        </div>
                      </div>
                      <div className="uk-margin-bottom">
                        <div className="uk-form-label"><ruby><rb>詳</rb><rp>(</rp><rt>くわ</rt><rp>)</rp></ruby>しい<ruby><rb>内容</rb><rp>(</rp><rt>ないよう</rt><rp>)</rp></ruby></div>
                        <div className="uk-form-controls">
                          <textarea id="modal-issuelist_sldetail" className="uk-textarea textarea_fixed" rows="3" value={selectedIssue.detail} disabled></textarea>
                        </div>
                      </div>
                    </form>
                  </div>
                )}
              </div>
              */}
            </div>
          </div>
          <button className="uk-modal-close-default" type="button" uk-close="true"></button>
        </div>
      </div>
    </>
  );
};
