import React, {useEffect} from 'react';
import '../App.css';
import {useNavigate} from 'react-router-dom';
import {useUserState} from '../hooks/useUserState';
import {usePasswordSecureQuestionForgotFormState} from '../hooks/usePasswordSecureQuestionForgotFormState';
import {PasswordSecureQuestionForgotComplete} from '../components/modal/passwordSecureQuestionForgotComplete';

const PasswordSecureQuestionForgot = () => {
  const navigate = useNavigate();
  const {user} = useUserState();
  const {passwordSecureQuestionForgotForm, setPasswordSecureQuestionForgotForm, postPasswordSecureQuestionForgotForm} = usePasswordSecureQuestionForgotFormState();

  useEffect(() => {
    if (user.ucl !== '') {
      navigate('/')
    }
  }, [user]);

  const handleBack = (event) => {
    navigate('/password_forgot')
  }

  const handleChange = (event) => {
    setPasswordSecureQuestionForgotForm({
      ...passwordSecureQuestionForgotForm,
      [event.target.name]: event.target.value
    });
  }

  const handleSQForgotSubmit = () => {
    if (passwordSecureQuestionForgotForm.uid === '') {
      setPasswordSecureQuestionForgotForm({
        ...passwordSecureQuestionForgotForm,
        errorMsg: "IDを入力してください。"
      });
      return;
    } else if (passwordSecureQuestionForgotForm.school === '') {
      setPasswordSecureQuestionForgotForm({
        ...passwordSecureQuestionForgotForm,
        errorMsg: "学校名を入力してください。"
      });
      return;
    } else if (passwordSecureQuestionForgotForm.grade === '') {
      setPasswordSecureQuestionForgotForm({
        ...passwordSecureQuestionForgotForm,
        errorMsg: "学年を入力してください。"
      });
      return;
    } else if (passwordSecureQuestionForgotForm.class === '') {
      setPasswordSecureQuestionForgotForm({
        ...passwordSecureQuestionForgotForm,
        errorMsg: "クラスを入力してください。"
      });
      return;
    } else if (passwordSecureQuestionForgotForm.name === '') {
      setPasswordSecureQuestionForgotForm({
        ...passwordSecureQuestionForgotForm,
        errorMsg: "名前を入力してください。"
      });
      return;
    }

    const res = postPasswordSecureQuestionForgotForm()
    if (!res) {
      setPasswordSecureQuestionForgotForm({
        ...passwordSecureQuestionForgotForm,
        errorMsg: "送信に失敗しました。"
      });
      return;
    }

    document.getElementById("pwResetDone").click();
  }

  return (
    <>
      <div className="uk-container uk-animation-fade">
        <div className="uk-margin uk-text-center">
          <div className={passwordSecureQuestionForgotForm.errorMsg ? "uk-alert-danger uk-margin-top uk-margin-remove-bottom uk-align-center uk-padding-small" : "uk-hidden"} uk-alert="true">
            <a className="uk-alert-close" uk-close="true"></a>
            <p className="uk-margin-remove">{passwordSecureQuestionForgotForm.errorMsg}</p>
          </div>
          <p className="uk-text-center">
            <ruby><rb>秘密</rb><rp>(</rp><rt>ひみつ</rt><rp>)</rp></ruby>の<ruby><rb>質問</rb><rp>(</rp><rt>しつもん</rt><rp>)</rp></ruby>が<ruby><rb>分</rb><rp>(</rp><rt>わ</rt><rp>)</rp></ruby>からないときは、ここからあなたのIDを<ruby><rb>教</rb><rp>(</rp><rt>おし</rt><rp>)</rp></ruby>えてください。
            <br />
            あなたのパスワードを<ruby><rb>先生</rb><rp>(</rp><rt>せんせい</rt><rp>)</rp></ruby>に<ruby><rb>教</rb><rp>(</rp><rt>おし</rt><rp>)</rp></ruby>えてもらうことができます。
          </p>

          <input className="uk-input uk-margin-remove-top uk-margin-small-bottom uk-width-medium uk-align-center" id="sqForgotID" name="uid" value={ passwordSecureQuestionForgotForm.uid} onChange={handleChange} type="text" placeholder="あなたのIDを入力してください" />
          <input className="uk-input uk-margin-remove-top uk-margin-small-bottom uk-width-medium uk-align-center" id="sqForgotSchool" name="school" value={ passwordSecureQuestionForgotForm.school} onChange={handleChange} type="text" placeholder="学校名" />
          <input className="uk-input uk-margin-remove-top uk-margin-small-bottom uk-width-medium uk-align-center" id="sqForgotGrade" name="grade" value={ passwordSecureQuestionForgotForm.grade} onChange={handleChange} type="text" placeholder="学年" />
          <input className="uk-input uk-margin-remove-top uk-margin-small-bottom uk-width-medium uk-align-center" id="sqForgotClass" name="class" value={ passwordSecureQuestionForgotForm.class} onChange={handleChange} type="text" placeholder="クラス" />
          <input className="uk-input uk-margin-remove-top uk-margin-small-bottom uk-width-medium uk-align-center" id="sqForgotName" name="name" value={ passwordSecureQuestionForgotForm.name} onChange={handleChange} type="text" placeholder="名前" />
        </div>
        <div className="uk-child-width-1-2 uk-margin-top uk-margin-bottom" uk-grid="true">
          <div>
            <button className="uk-button uk-button-default uk-width-small uk-margin-top uk-align-right" onClick={handleBack}>もどる</button>
          </div>
          <div>
            <button className="uk-button uk-button-primary uk-width-small uk-margin-top uk-align-left" type="button" onClick={handleSQForgotSubmit}>IDをおくる</button>            
            <button id="pwResetDone" className="uk-button uk-hidden" uk-toggle="target: #modal-pwResetDone"></button>
          </div>
        </div>
      </div>
      <PasswordSecureQuestionForgotComplete />
    </>
  );
};

export default PasswordSecureQuestionForgot;
