import React, {useEffect, useState} from 'react';
import '../../App.css';
import {useUserState} from '../../hooks/useUserState';
import moment from 'moment';
import {IssueDetailUpdateComplete} from '../../components/modal/issueDetailUpdateComplete';
import {IssueDetailUpdateError} from '../../components/modal/issueDetailUpdateError';
import {getCookie} from '../../utils/cookie';
import {useIssuesState} from '../../hooks/useIssuesState';

export const IssueDetail = ({issue}) => {
  const {user} = useUserState();
  const [memo, setMemo] = useState(issue?.memo);
  const [status, setStatus] = useState(issue?.status);
  const {fetchIssues} = useIssuesState();

  useEffect(() => {
    setMemo(String(issue?.memo === undefined || issue?.memo === true ? '' : issue?.memo))
    setStatus(String(issue?.status))
  }, [issue]);

  const handleUpdateIncident = async (event) => {
    let submitBody = {
      memo: memo,
      status: status,
    };   

    const url = 'https://4rhuaomwqc.execute-api.ap-northeast-1.amazonaws.com/v1/api/issues/' + issue.id + '/status';
    const method = 'PUT';
    const headers = {
      'Authorization': getCookie('Authorization')
    };
    const body = JSON.stringify(submitBody);

    const res = await fetch(url, {method, headers, body})
    await res.json();
    if (!res.ok) {
      document.getElementById('issueDetailIncidentSubmitError').click();
      return;
    }

    await fetchIssues();

    document.getElementById('issueDetailClose').click();
    document.getElementById('issueDetailIncidentSubmit').click();
  }

  const handleStatusChange = (event) => {
    setStatus(event.target.value)
  }

  const handleMemoChange = (event) => {
    setMemo(event.target.value)
  }

  const handleInsertMemoTemplate = () => {
    var date = new Date();
    var dateStr = ('0' + (date.getMonth() + 1)).slice(-2)
      + '月' + ('0' + date.getDate()).slice(-2)
      + '日' + ('0' + date.getHours()).slice(-2)
      + '時';

    const memoTemplate = [
      '匿名なので見守り中\n\n',
      dateStr + 'ごろ、○○が聞き取り予定\n\n',
      dateStr + 'ごろ、○○が聞き取り完了\n\n',
      dateStr + 'ごろ、○○が対応中\n\n',
      dateStr + 'ごろ、○○が対応完了\n\n',
    ];

    let templateNumber = Number(document.getElementById('issueMemoTempateSelect').value);
    setMemo(memoTemplate[templateNumber] + memo);
  }

  return (
    <>
      <div className="uk-flex-top" id="modal-detail" uk-modal="true">
        <div className="uk-modal-dialog uk-margin-auto-vertical uk-modal-body">
            <h4 className="uk-text-bold uk-heading-bullet">{moment(issue?.created_at).format('YYYY/MM/DD HH:mm')} {issue?.overview}</h4>
            <form className="uk-width-1-1 uk-form-horizontal">
              <div className="uk-margin-bottom">
                <label className="uk-form-label">相談者の名前</label>
                <div className="uk-form-controls">
                  <p className="uk-margin-remove-bottom">{issue?.created_name}</p>
                  <p className="uk-text-meta uk-margin-remove-top">相談者が自分で入力した名前です</p>
                </div>
              </div>
              <div className="uk-margin-bottom">
                <div className="uk-form-label">いつおきましたか？</div>
                <div className="label">{issue?.when}</div>
              </div>
              <div className="uk-margin-bottom">
                <div className="uk-form-label">今も続いている</div>
                <div className="label">{issue?.progress === true ? "はい" : "いいえ"}</div>
              </div>
              <div className="uk-margin-bottom">
                <div className="uk-form-label">どうしましたか？</div>
                <div className="label">{issue?.target}</div>
              </div>
              <div className="uk-margin-bottom">
                <div className="uk-form-label">何がおきましたか？</div>
                <div className="label">{issue?.overview}</div>
              </div>
              <div className="uk-margin-bottom">
                <div className="uk-form-label">相手は誰ですか？</div>
                <div className="label">{issue?.opponent}</div>
              </div>
              <div className="uk-margin-bottom">
                <div className="uk-form-label">目撃した人はいますか？</div>
                <div className="label">{issue?.observer}</div>
              </div>
              <div className="uk-margin-bottom">
                <div className="uk-form-label">詳しい内容</div>
                <div className="label">{issue?.detail}</div>
              </div>
              <div className="uk-margin-bottom">
                <div className="uk-form-label">相談者ID</div>
                <div className="label">{issue?.owner_id}</div>
              </div>
              <hr />
              <div className="uk-margin-bottom">
                <div className="uk-form-label">対応状況を選ぶ</div>
                <div className="uk-form-controls">
                  <select className="uk-select" id="issueStatusSelect" value={status} disabled={user.ucl !== 'teacher'} onChange={handleStatusChange} >
                    <option value="0">未対応</option>
                    <option value="1">対応中</option>
                    <option value="2">対応済</option>
                  </select>
                </div>
              </div>
              <div className="uk-margin-bottom">
                <div className="uk-form-label">対応内容を入力</div>
                <div className="uk-form-controls">
                  <textarea id="issueMemo" className="uk-textarea textarea_fixed" value={memo} onChange={handleMemoChange} rows="5" placeholder="対応の履歴を記録してください" disabled={user.ucl !== 'teacher'}></textarea>
                </div>
              </div>
              <div className="uk-margin-bottom">
                <div className="uk-form-label">対応内容の例</div>
                <div className="uk-form-controls">
                  <select disabled={user.ucl !== 'teacher'} className="uk-select uk-margin-bottom" id="issueMemoTempateSelect">
                    <option value="0">匿名なので見守り中</option>
                    <option value="1">聞き取り予定</option>
                    <option value="2">聞き取り完了</option>
                    <option value="3">対応中</option>
                    <option value="4">対応完了</option>
                  </select>
                  <button type="button" disabled={user.ucl !== 'teacher'} className="uk-button" onClick={() => handleInsertMemoTemplate()}>選択したものを内容に反映</button>
                </div>
              </div>

            </form>
            {user.ucl === 'teacher' && (
              <button id="updateIssue" className="uk-button uk-button-danger uk-width-medium uk-margin-top uk-align-center uk-margin-small-bottom" onClick={handleUpdateIncident}>
                相談を更新する
              </button>
            )}
            <button id="issueDetailIncidentSubmit" className="uk-button uk-hidden" uk-toggle="target: #modal-incident-submit"> </button>   
            <button id="issueDetailIncidentSubmitError" className="uk-button uk-hidden" uk-toggle="target: #modal-incident-submit-error"> </button>   
            <button id="issueDetailClose" className="uk-modal-close-default" type="button" uk-close="true"></button>
        </div>
      </div>
      <IssueDetailUpdateComplete />
      <IssueDetailUpdateError />
    </>
  );
};
