import React, {useEffect} from 'react';
import '../../App.css';
import {EbNavigationBar} from '../../components/navi/ebNavigationBar';
import {UsersList} from '../../components/usersList';
import {useUserState} from '../../hooks/useUserState';
import {useNavigate} from 'react-router-dom';

const EbUsersPage = () => {
  const navigate = useNavigate();
  const {user, isLoggedin} = useUserState();

  useEffect(() => {
    if (isLoggedin() === false) {
      navigate('/')
    }
    if (user.area === "山形県村山市") {
      navigate('/eb/expire')
    }
  }, []);

  useEffect(() => {
    if (user.ucl && user.ucl !== 'eb') {
      navigate('/')
    }
  }, [user]);

  return (
    <div className="wrapper">
      <div className='uk-width-1-1 uk-flex uk-flex-middle'>
        <EbNavigationBar />
        <UsersList />
      </div>
    </div>
  );
};

export default EbUsersPage;
