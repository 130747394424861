import React, {useEffect, useState} from 'react';
import '../App.css';
import toptitle from '../images/toptitle.png';
import {useLoginFormState} from '../hooks/useLoginFormState';
import {useUserState} from '../hooks/useUserState';
import {openDocument} from '../utils/openDocument';
import {useLoadingState} from '../hooks/useLoadingState';
import {useNavigate} from 'react-router-dom';

const LoginPage = () => {
  const navigate = useNavigate();
  const {setLoading} = useLoadingState();
  const {loginForm, setLoginForm, postLoginForm} = useLoginFormState();
  const {user, isLoggedin} = useUserState();
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (isLoggedin() === false) {
      return;
    }

    if (user.area === "山形県村山市") {
      return navigate('/' + user.ucl + '/expire')
    }

    if (user.ucl === 'eb') {
      navigate('/eb/messages')
    } else if (user.ucl === 'teacher') {
      navigate('/teacher/issues')
    } else if (user.ucl === 'student') {
      navigate('/student')
    }
  }, [user]);

  const handleLogin = async () => {
    if (loginForm.email === '' && loginForm.password === '') {
      setLoginForm({
        ...loginForm,
        errorMsg: 'エラー: メールアドレスとパスワードを入力してください'
      });
      return;
    } else if (loginForm.email === '') {
      setLoginForm({
        ...loginForm,
        errorMsg: 'エラー: メールアドレスを入力してください'
      });
      return;
    } else if (loginForm.password === '') {
      setLoginForm({
        ...loginForm,
        errorMsg: 'エラー: パスワードを入力してください'
      });
      return;
    }

    setLoading(true);

    const res = await postLoginForm();
    if (res === false) {
      setLoginForm({
        ...loginForm,
        errorMsg: 'エラー: メールアドレスかパスワードが間違っています'
      });
    }
    setLoading(false);
  }

  const handleChange = (event) => {
    setLoginForm({
      ...loginForm,
      [event.target.name]: event.target.value
    });
  }

  const handlePasswordForgot = (event) => {
    navigate('/password_forgot')
  }

  const handleOpenDocument = (event) => {
    openDocument(event.currentTarget.id);
  }

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  }

  return (
    <div className="wrapper background-image-display">
      <div className='uk-width-1-1 uk-flex uk-flex-middle'>
        <div className='uk-container uk-animation-fade'>
          <img className='uk-align-center uk-width-small' src={toptitle} alt="title" width="300" />
          {loginForm.errorMsg && (
            <div className="uk-alert-danger uk-margin-top uk-margin-bottom uk-width-medium uk-align-center uk-padding-small" uk-alert="true">
              <a className="uk-alert-close" uk-close="true"></a>
              <p className="uk-margin-remove">{loginForm.errorMsg}</p>
            </div>
          )}
          <div className="uk-margin-small-bottom">
            <div className="uk-inline">
              <span className="uk-form-icon" uk-icon="icon: user"></span>
              <input className="uk-input uk-margin-remove-top uk-margin-remove-bottom uk-width-medium uk-align-center" id="mailinput" name='email' type="text" placeholder="メールアドレス" value={loginForm.email} onChange={handleChange}/>
            </div>
          </div>
          <div className="uk-margin-remove-top uk-margin-bottom">
            <div className="uk-inline">
              <span className="uk-form-icon" uk-icon="icon: lock"></span>
              <input className="uk-input uk-margin-remove-top uk-margin-remove-bottom uk-width-medium uk-align-center" id="passinput" type={showPassword ? 'text' : 'password'} name='password' placeholder="パスワード" value={loginForm.password} onChange={handleChange}/>
            </div>
            <div className="uk-block" style={{marginLeft: "10px", textAlign: "center"}}>
              <a onClick={toggleShowPassword} style={{marginLeft: "10px"}}>
                {showPassword ? 'パスワードを隠す' : 'パスワードを表示'}
              </a>
            </div>
          </div>
          <button className="uk-button uk-button-primary uk-width-medium uk-align-center" type="button" onClick={handleLogin}>ログイン</button> 
          <button className="uk-button uk-hidden" id="modal-error" type="button" uk-toggle="target: #modal-error"></button>
          <hr />
          <button className="uk-button uk-button-default uk-width-medium uk-margin-small-bottom uk-align-center" type="button" onClick={handlePasswordForgot}>パスワードがわからない</button>
          <button id="kiyaku" className="uk-button uk-button-text uk-margin-top uk-align-center" type="button" onClick={handleOpenDocument}>マモレポ利用規約</button>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
