import React from 'react';
import '../../App.css';

export const StudentHowTo = () => {
  return (
    <>
      <div className="uk-flex-top" id="modal-howto" uk-modal="true">
        <div className="uk-modal-dialog uk-margin-auto-vertical uk-modal-body">
          <video className="uk-text-center" width="540" height="315" uk-video="true" src="https://lion.mamorepo.com/document/tutorial.mp4" controls></video>
          <p className="uk-text-center">
            <button className="uk-button uk-button-default uk-modal-close" type="button">閉じる</button>
          </p>
        </div>
      </div>
    </>
  );
};
