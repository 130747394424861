import { useCallback, useEffect } from 'react';
import { atom, useRecoilState } from 'recoil';
import {useUserState} from '../hooks/useUserState';
import {setCookie} from '../utils/cookie';
import {useLocation} from 'react-router-dom';

export const loginFormState = atom({
  key: 'loginFormState',
  default: {
    email: '',
    password: '',
    errorMsg: ''
  },
});

export const useLoginFormState = () => {
  const [loginForm, setLoginForm] = useRecoilState(loginFormState);
  const {setUserFromIdToken} = useUserState();

  const query = new URLSearchParams(useLocation().search);
  const email = query.get('email')
  const token = query.get('token')
  const password = token ? atob(token) : ""

  useEffect(() => {
    if (email) {
      setLoginForm({
        email: email,
        password: password,
        errorMsg: ''
      })
    }
  }, []);

  const postLoginForm = useCallback(
    async () => {
      const submitBody = {
        username: loginForm.email,
        password: loginForm.password,
      };   

      const url = 'https://4rhuaomwqc.execute-api.ap-northeast-1.amazonaws.com/v1/api/auth/signin';
      const method = 'POST';
      const headers = {};
      const body = JSON.stringify(submitBody);

      const res = await fetch(url, {method, headers, body});
      const data = await res.json();

      if (!res.ok) {
        return false;
      }

      setCookie(data['id_token'], data['access_token'])
      setUserFromIdToken(data['id_token']);

      return data;
    },
    [loginForm]
  );

  return {
    loginForm: loginForm,
    setLoginForm: setLoginForm,
    postLoginForm,
  };
};
