import { useCallback } from 'react';
import { atom, useRecoilState } from 'recoil';
import {decodeJwt} from '../utils/jwt';
import {getCookie, setCookie} from '../utils/cookie';
import {useNavigate} from 'react-router-dom';

const userStateDefault = {
  email: '',
  area: '',
  school: '',
  grade: '',
  ucl: ''
}

export const userState = atom({
  key: 'userState',
  default: userStateDefault,
});

export const useUserState = () => {
  const navigate = useNavigate();
  const [user, setUser] = useRecoilState(userState);

  const setUserFromIdToken = useCallback(
    (idToken) => {
      const userData = decodeJwt(idToken);
      setUser({
        email: userData['email'],
        area: userData['custom:area'],
        school: userData['custom:school'],
        grade: userData['custom:grade'] ?? '',
        ucl: userData['custom:role']
      });
    },
    []
  );

  const isLoggedin= useCallback(
    () => {
      const idToken = getCookie('Authorization');
      const accessToken = getCookie('Accesstoken');

      if (!idToken || !accessToken) {
        return false
      }

      setUserFromIdToken(idToken);

      return true;
    },
    []
  );

  const logout = useCallback(
    () => {
      setCookie('', '');
      setUser(userStateDefault);
      navigate('/');
    },
    []
  );

  return {
    user: user,
    setUser: setUser,
    setUserFromIdToken,
    logout,
    isLoggedin,
  };
};
