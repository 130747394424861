import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './pages/login';
import PasswordChangePage from './pages/passwordChange';
import PasswordForgotPage from './pages/passwordForgot';
import PasswordForgotAskPage from './pages/passwordForgotAsk';
import PasswordSecureQuestionForgotPage from './pages/passwordSecureQuestionForgot';
import EbIssuesPage from './pages/eb/issues';
import EbMessagesPage from './pages/eb/messages';
import EbUsersPage from './pages/eb/users';
import TeacherIssuesPage from './pages/teacher/issues';
import StudentTopPage from './pages/student/index';
import StudentConsultNamePage from './pages/student/consult/name';
import StudentConsultWhenPage from './pages/student/consult/when';
import StudentConsultTargetPage from './pages/student/consult/target';
import StudentConsultOverviewPage from './pages/student/consult/overview';
import StudentConsultDetalPage from './pages/student/consult/detail';
import StudentConsultSummaryPage from './pages/student/consult/summary';

import StudentExpirePage from './pages/student/expire';
import TeacherExpirePage from './pages/teacher/expire';
import EbExpirePage from './pages/eb/expire';

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        {/* TOP */}
        <Route path="/" exact element={<LoginPage />} />
        <Route path="/password_change" exact element={<PasswordChangePage />} />
        <Route path="/password_forgot" exact element={<PasswordForgotAskPage />} />
        <Route path="/password_secure_question_forgot" exact element={<PasswordSecureQuestionForgotPage />} />
        {/* eb */}
        <Route path="/eb/issues" exact element={<EbIssuesPage />} />
        <Route path="/eb/messages" exact element={<EbMessagesPage />} />
        <Route path="/eb/users" exact element={<EbUsersPage />} />
        {/* teacher */}
        <Route path="/teacher/issues" exact element={<TeacherIssuesPage />} />
        {/* student */}
        <Route path="/student/" exact element={<StudentTopPage />} />
        <Route path="/student/consult/1" exact element={<StudentConsultNamePage />} />
        <Route path="/student/consult/2" exact element={<StudentConsultWhenPage />} />
        <Route path="/student/consult/3" exact element={<StudentConsultTargetPage />} />
        <Route path="/student/consult/4" exact element={<StudentConsultOverviewPage />} />
        <Route path="/student/consult/5" exact element={<StudentConsultDetalPage />} />
        <Route path="/student/consult/6" exact element={<StudentConsultSummaryPage />} />

        <Route path="/student/expire" exact element={<StudentExpirePage />} />
        <Route path="/teacher/expire" exact element={<TeacherExpirePage />} />
        <Route path="/eb/expire" exact element={<EbExpirePage />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
