import React, {useEffect} from 'react';
import '../App.css';
import {useNavigate} from 'react-router-dom';
import {useUserState} from '../hooks/useUserState';
import {usePasswordForgotFormState} from '../hooks/usePasswordForgotFormState';

const PasswordForgotAskPage = () => {
  const navigate = useNavigate();
  const {user} = useUserState();
  const {passwordForgotForm, setPasswordForgotForm, postPasswordForgotForm} = usePasswordForgotFormState();

  useEffect(() => {
    if (user.ucl !== '') {
      navigate('/')
    }
  }, [user]);

  const handleBack = (event) => {
    navigate('/')
  }

  const handlePasswordSecureQuestionForgot = (event) => {
    navigate('/password_secure_question_forgot')
  }

  const handleChange = (event) => {
    setPasswordForgotForm({
      ...passwordForgotForm,
      [event.target.name]: event.target.value
    });
  }

  const handlePasswordForgotSubmit = () => {
    if (passwordForgotForm.ansSeqQuestion === '') {
      setPasswordForgotForm({
        ...passwordForgotForm,
        errorMsg: "秘密の質問を選択してください。"
      });
      return;
    } else if (passwordForgotForm.ansSeqQuestionAnswer === '') {
      setPasswordForgotForm({
        ...passwordForgotForm,
        errorMsg: "秘密の質問の答えを入力してください。"
      });
      return;
    } else if (passwordForgotForm.ansSeqQuestionId === '') {
      setPasswordForgotForm({
        ...passwordForgotForm,
        errorMsg: "あなたのIDを入力してください。"
      });
      return;
    }

    const res = postPasswordForgotForm()
    if (!res) {
      setPasswordForgotForm({
        ...passwordForgotForm,
        errorMsg: "秘密の質問の答えがまちがっています。"
      });
      return;
    }

    navigate('/password_change')
  }

  return (
    <>
      <div className="uk-container uk-animation-fade">
        <div className={passwordForgotForm.errorMsg ? "uk-alert-danger uk-margin-top uk-margin-remove-bottom uk-align-center uk-padding-small" : "uk-hidden"} uk-alert="true">
          <a className="uk-alert-close" uk-close="true"></a>
          <p className="uk-margin-remove">{passwordForgotForm.errorMsg}</p>
        </div>
        <div className="uk-margin uk-text-center">
          <p className="uk-text-center">
            自分のIDやパスワードがわからない場合は、先生から配布された紙を確認してください。
            <br />
            紙をなしくた場合は、先生に確認してください。
          </p>
        </div>
      </div>
    </>
  );
};

export default PasswordForgotAskPage;
