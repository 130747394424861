import React, {useEffect} from 'react';
import '../../App.css';
import {useUserState} from '../../hooks/useUserState';
import {useMessageSendFormState} from '../../hooks/useMessageSendFormState';
import {useMessagesState} from '../../hooks/useMessagesState';
import {useIssuesState} from '../../hooks/useIssuesState';
import moment from 'moment';

export const IssueCommunicate = ({selectedStudentId}) => {
  const {user} = useUserState();
  const {messages, fetchMessages} = useMessagesState();
  const {issues, fetchIssues} = useIssuesState();
  const {messageSendForm, setMessageSendForm, postMessageSendForm, clearMessageSendForm} = useMessageSendFormState();

  useEffect(() => {
    const getInitialData = async () => {
      if (issues.data.length === 0) {
        await fetchIssues();
      }
      if (selectedStudentId) {
        setMessageSendForm({
          ...messageSendForm,
          toStudentId: selectedStudentId
        });
        await fetchMessages(selectedStudentId);
      }
    };

    getInitialData();
  }, [selectedStudentId]);

  const handleChange = (event) => {
    setMessageSendForm({
      ...messageSendForm,
      [event.target.name]: event.target.value
    });
  }

  const handleMessageSend = async () => {
    if (messageSendForm.message === '') {
      setMessageSendForm({
        ...messageSendForm,
        errorMessage: 'メッセージを入力してください。'
      });
      return;
    }

    const res = await postMessageSendForm();

    if (res === false) {
      setMessageSendForm({
        ...messageSendForm,
        errorMessage: '送信に失敗しました。'
      });
      return;
    }

    clearMessageSendForm();
    fetchMessages(selectedStudentId);
  }

  const messageDownLoad = () => {
    let content = '';
    for (let i = 0; i < messages.data.length; i++){
      content = content +
        moment(messages.data[i].created_at).format('YYYY/MM/DD HH:mm') +
        ' ' + 
        (messages.data[i].send_user === true ? '相談者' : '教育委員会からの返信') +
        '\n' +
        messages.data[i].talk +
        '\n\n';
    }
    var blob = new Blob([content], { "type" : "text/plain" });
    if (window.navigator.msSaveBlob) { 
      window.navigator.msSaveBlob(blob, 'message' + Date.now() + '.txt'); 
    } else {
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob);
      link.download = 'message' + Date.now() + '.txt'
      link.click();
    }
  }

  return (
    <>
      <div className="uk-modal-container" id="modal-communicate" uk-modal="true">
        <div className="uk-modal-dialog">
          <button className="uk-modal-close-default" type="button" uk-close="true"></button>
          <div className="uk-modal-body uk-margin-remove uk-padding-remove">
            <div id="studentWindow" className={user.ucl === 'eb' ? "uk-grid-collapse uk-child-width-1-2" : "uk-grid-collapse uk-child-width-1-1"} uk-grid="true">
              <div className="uk-padding-small">
                <h3 className="uk-text-center">相談の記録</h3>
                <div className="uk-panel uk-panel-scrollable uk-height-mlarge">
                  <ul className="uk-list uk-list-divider" uk-accordion="true">
                    {issues.data.length === 0 && (
                      <li key="communicate-issues-list-li-none">
                        <p>相談はありません。</p>
                      </li>
                    )}
                    {issues.data.map((issue, i) => (
                      <React.Fragment key={`communicate-issues-list-div-${i}`}>
                        {issue?.owner_id === selectedStudentId && issue?.anonymous === false && (
                          <li key={`communicate-issues-list-li-${i}`}>
                            <a className="uk-accordion-title uk-text-bold" href="#">{moment(issue.created_at).format('YYYY/MM/DD HH:mm') + ' ' + issue.overview}</a>
                            <div className="uk-accordion-content">
                              <form className="uk-width-1-1 uk-form-horizontal">
                                <div className="uk-margin-bottom">
                                  <div className="uk-form-label">いつおきましたか？</div>
                                  <div className="label">{issue?.when}</div>
                                </div>
                                <div className="uk-margin-bottom">
                                  <div className="uk-form-label">今も続いている</div>
                                  <div className="label">{issue?.progress === true ? "はい" : "いいえ"}</div>
                                </div>
                                <div className="uk-margin-bottom">
                                  <div className="uk-form-label">どうしましたか？</div>
                                  <div className="label">{issue?.target}</div>
                                </div>
                                <div className="uk-margin-bottom">
                                  <div className="uk-form-label">何がおきましたか？</div>
                                  <div className="label">{issue?.overview}</div>
                                </div>
                                <div className="uk-margin-bottom">
                                  <div className="uk-form-label">場所はどこですか？</div>
                                  <div className="label">{issue?.location}</div>
                                </div>
                                <div className="uk-margin-bottom">
                                  <div className="uk-form-label">相手は誰ですか？</div>
                                  <div className="label">{issue?.opponent}</div>
                                </div>
                                <div className="uk-margin-bottom">
                                  <div className="uk-form-label">目撃した人はいますか？</div>
                                  <div className="label">{issue?.observer}</div>
                                </div>
                                <div className="uk-margin-bottom">
                                  <div className="uk-form-label">詳しい内容</div>
                                  <div className="label">{issue?.detail}</div>
                                </div>
                              </form>
                            </div>
                          </li>
                        )}
                      </React.Fragment>
                    ))}
                  </ul>              
                </div>
              </div>
              {user.ucl === 'eb' && (
                <div id="msgWindow" className="uk-padding-small">
                  <h3 className="uk-text-center">メッセージ</h3>
                  <div id="adminMessageList" className="uk-panel uk-panel-scrollable uk-height-medium">
                    <ul className="uk-list uk-list-divider">
                      {messages.data.map((message, i) => (
                        <li key={`communicate-student-message-li-${i}`}>
                          <p className="uk-text-small uk-text-bold uk-margin-remove-bottom">{message.send_user === true ? '相談者' : '教育委員会からの返信'}</p>
                          <p className="uk-margin-remove-top uk-margin-remove-bottom messageBox">{message.talk}</p>
                          <p className="uk-margin-remove-top uk-text-small uk-text-muted uk-text-right">{moment(message.created_at).format('YYYY/MM/DD HH:mm')}</p>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="uk-inline uk-width-1-1 uk-margin-top">
                    <textarea id="talkChild" className="uk-textarea textarea_fixed" rows="3" placeholder="メッセージを書いてください" name="message" value={messageSendForm.message} onChange={handleChange}></textarea>
                  </div>
                  {messageSendForm.errorMessage && (
                    <div className='uk-alert-danger uk-margin-top uk-margin-remove-bottom uk-align-center uk-padding-small' uk-alert="true">
                      <p className="uk-margin-remove">{messageSendForm.errorMessage}</p>
                    </div>
                  )}
                  <div className="uk-width-1-1 uk-margin-top uk-text-right">
                    <button className="uk-button uk-button-default uk-margin-small-right" type="button" onClick={messageDownLoad}>メッセージ履歴をダウンロード</button>
                    <button className="uk-button uk-button-danger" id="sendTeacher" onClick={handleMessageSend}>メッセージを送る</button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
