import React, {useEffect} from 'react';
import '../../../App.css';
import {useUserState} from '../../../hooks/useUserState';
import {useStudentConsultFormState} from '../../../hooks/useStudentConsultFormState';
import {useNavigate} from 'react-router-dom';
import {StudentNavigationBar} from '../../../components/navi/studentNavigationBar';
import {enums} from '../../../utils/enums';
import target1_0 from '../../../images/target1_0.png';
import target2_0 from '../../../images/target2_0.png';
import target3_0 from '../../../images/target3_0.png';
import target4_0 from '../../../images/target4_0.png';
import target1_1 from '../../../images/target1_1.png';
import target2_1 from '../../../images/target2_1.png';
import target3_1 from '../../../images/target3_1.png';
import target4_1 from '../../../images/target4_1.png';

const disabledImage = [
  target1_0,
  target2_0,
  target3_0,
  target4_0
];

const enabledImage = [
  target1_1,
  target2_1,
  target3_1,
  target4_1
]

const CURRENT_PAGE_NUM = 3

const StudentConsultTargetPage = () => {
  const navigate = useNavigate();
  const {user} = useUserState();
  const {studentConsultForm, setStudentConsultFormState} = useStudentConsultFormState();

  useEffect(() => {
    if (user.ucl !== 'student') {
      navigate('/')
    }
  }, []);

  const handleSelectTarget = (event) => {
    const selectedIndex = Number(event.currentTarget.id.replace('target_', ''));
    const selectedValue = enums.consultTarget[selectedIndex];

    setStudentConsultFormState({
      ...studentConsultForm,
      target: selectedValue
    });
  }

  const handlePrev = (event) => {
    if (CURRENT_PAGE_NUM - 1 > 0) {
      navigate('/student/consult/' + String(CURRENT_PAGE_NUM - 1))
      setStudentConsultFormState({
        ...studentConsultForm,
        errorMessage: '',
      });
    } else {
      setStudentConsultFormState({
        ...studentConsultForm,
        errorMessage: '',
      });
      navigate('/student')
    }
  }

  const handleNext = (event) => {
    if (studentConsultForm.target === '') {
      setStudentConsultFormState({
        ...studentConsultForm,
        errorMessage: 'エラー: どうしたか選択してください',
      });
      return;
    }
    setStudentConsultFormState({
      ...studentConsultForm,
      errorMessage: '',
    });
    navigate('/student/consult/' + String(CURRENT_PAGE_NUM + 1))
  }

  return (
    <div className="wrapper">
      <div className='uk-width-1-1 uk-flex uk-flex-middle'>
        <StudentNavigationBar disabledToTop />
        <div className="uk-width-1-1 uk-position-top uk-margin-large-top uk-container uk-animation-fade">
          <h1 className="uk-text-center uk-margin-large-top uk-margin-large-bottom">誰のことですか？<br />(どうしましたか？)</h1>
          <div className="uk-panel uk-width-expand uk-text-center">
            <form className="uk-width-3-4@l uk-form-stacked uk-align-center">
              <p className={studentConsultForm.errorMessage ? "uk-text-danger uk-margin-remove-top" : 'uk-hidden'}>{studentConsultForm.errorMessage}</p>
              <ul className="uk-child-width-1-4@l" uk-grid="true">
                {enums.consultTarget.map((target, i) => (
                  <li id={`target_${i}`} onClick={handleSelectTarget} className="uk-width-1-4@l uk-margin-small-bottom uk-margin-small-top">
                    <div className={`uk-card ${studentConsultForm.target === target ? 'uk-card-primary' : 'uk-card-default'} uk-card-small selectCard`}>
                      <div className="uk-card-body uk-padding-remove uk-text-center">
                        <div className="uk-grid-collapse@l uk-flex-middle" uk-grid="true">
                          <div className="uk-width-1-3 uk-width-1-1@l uk-text-center">
                            <img className="selectImage" src={studentConsultForm.target === target ? enabledImage[i] : disabledImage[i]} />
                          </div>
                          <div className="uk-width-expand uk-margin-remove-top">
                            <h4 className="uk-margin-top uk-margin-bottom cardtext">{target}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </form>
          </div>
          <div className="uk-child-width-1-2 uk-margin-large-top" uk-grid="true">
            <div>
              <button className="uk-button uk-button-primary uk-width-small uk-margin-top uk-align-right" onClick={handlePrev}><span uk-icon="triangle-left"></span>もどる</button>
            </div>
            <div>
              <button className="uk-button uk-button-primary uk-width-small uk-margin-top uk-align-left" onClick={handleNext}>つぎへ<span uk-icon="triangle-right"></span></button>            
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentConsultTargetPage;
