import React from 'react';
import '../../App.css';

export const StudentStart = () => {
  return (
    <>
      <div className="uk-flex-top" id="modal-start" uk-modal="true">
        <div className="uk-modal-dialog uk-margin-auto-vertical uk-modal-body">
          <div>
            <p className="uk-text-center">
              <ruby><rb>画面</rb><rp>(</rp><rt>がめん</rt><rp>)</rp></ruby>に<ruby><rb>質問</rb><rp>(</rp><rt>しつもん</rt><rp>)</rp></ruby>が<ruby><rb>表示</rb><rp>(</rp><rt>ひょうじ</rt><rp>)</rp></ruby>されます。<br />
              <br />
              <ruby><rb>当</rb><rp>(</rp><rt>あ</rt><rp>)</rp></ruby>てはまることがいくつもあるときは、<br />
              <ruby><rb>先生</rb><rp>(</rp><rt>せんせい</rt><rp>)</rp></ruby>に<ruby><rb>一番</rb><rp>(</rp><rt>いちばん</rt><rp>)</rp></ruby><ruby><rb>伝</rb><rp>(</rp><rt>つた</rt><rp>)</rp></ruby>えたいものを<ruby><rb>選</rb><rp>(</rp><rt>えら</rt><rp>)</rp></ruby>んでください。
            </p>
            <p className="uk-text-center">
              <button className="uk-button uk-button-default uk-modal-close" type="button">はじめる</button>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
