import React, {useEffect} from 'react';
import '../App.css';
import {useNavigate} from 'react-router-dom';
import {useUserState} from '../hooks/useUserState';
import {usePasswordChangeFormState} from '../hooks/usePasswordChangeFormState';

const PasswordChangePage = () => {
  const navigate = useNavigate();
  const {user} = useUserState();
  const {passwordChangeForm, setPasswordChangeForm, postPasswordChangeForm} = usePasswordChangeFormState();

  useEffect(() => {
    if (user.ucl === '') {
      navigate('/')
    }
  }, [user]);

  const handleBack = (event) => {
    navigate(-2)
  }

  const handleChange = (event) => {
    setPasswordChangeForm({
      ...passwordChangeForm,
      [event.target.name]: event.target.value
    });
  }

  const handlePWChange = () => {
    if (passwordChangeForm.oldPassword === '') {
      setPasswordChangeForm({
        ...passwordChangeForm,
        errorMsg: "現在のパスワードを入力してください。"
      });
      return;
    } else if (passwordChangeForm.password !== passwordChangeForm.confirmPassword) {
      setPasswordChangeForm({
        ...passwordChangeForm,
        errorMsg: "2つのテキストボックスに、同じパスワードを入力してください。"
      });
      return;
    } else if (passwordChangeForm.password === '') {
      setPasswordChangeForm({
        ...passwordChangeForm,
        errorMsg: "新しいパスワードを入力してください。"
      });
      return;
    } else if (!passwordChangeForm.password.match(/^(?=.*?[a-z])(?=.*?\d)[ -~]{6,100}$/)) {
      setPasswordChangeForm({
        ...passwordChangeForm,
        errorMsg: "新しいパスワードは、6文字以上で、アルファベット(a～z)と数字を両方使ってください。"
      });
      return;
    }

    postPasswordChangeForm()
  }

  return (
    <>
      <div className='uk-container uk-animation-fade'>
        <div className={passwordChangeForm.errorMsg ? 'uk-alert-danger uk-margin-top uk-margin-remove-bottom uk-align-center uk-padding-small' : 'uk-hidden'} uk-alert="true">
          <a className="uk-alert-close" uk-close="true"></a>
          <p className="uk-margin-remove">{passwordChangeForm.errorMsg}</p>
        </div>
        <div className="uk-margin">
          <p className="uk-text-center">
            あなたが決めたパスワードに変更します。
            <br />
            次回からは、ここに入力したパスワードでマモレポにログインしてください。
          </p>
          <p className="uk-text-center">
            あなたのメールアドレス: {user.email}
          </p>
          <input className="uk-input uk-margin-remove-top uk-margin-small-bottom uk-width-medium uk-align-center" id="newPass" name="oldPassword" type="password" placeholder="現在のパスワード" onChange={handleChange} />
          <input className="uk-input uk-margin-remove-top uk-margin-small-bottom uk-width-medium uk-align-center" id="newPass" name="password" type="password" placeholder="新しいパスワード" onChange={handleChange} />
          <input className="uk-input uk-margin-remove-top uk-margin-remove-bottom uk-width-medium uk-align-center" id="newPassConfirm" name="confirmPassword" type="password" placeholder="もう一度入力してください" onChange={handleChange} />
          <p className="uk-text-meta uk-text-center">パスワードは、6文字以上で、アルファベット(a～z)と数字を両方使ってください。</p>
        </div>
        <div className="uk-child-width-1-2 uk-margin-top uk-margin-bottom" uk-grid="true">
          <div>
            <button className="uk-button uk-button-default uk-width-small uk-margin-top uk-align-right" onClick={handleBack}>もどる</button>
          </div>
          <div>
            <button className="uk-button uk-button-primary uk-margin-top uk-align-left" type="button" onClick={handlePWChange}>パスワードを登録する</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PasswordChangePage;
