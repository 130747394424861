import { useCallback } from 'react';
import { atom, useRecoilState } from 'recoil';
import {useUserState} from '../hooks/useUserState';
import {getCookie, setCookie} from '../utils/cookie';

export const passwordForgotFormState = atom({
  key: 'passwordForgotFormState',
  default: {
    uid: '',
    question: '',
    answer: '',
    errorMsg: ''
  },
});

export const usePasswordForgotFormState = () => {
  const [passwordForgotForm, setPasswordForgotForm] = useRecoilState(passwordForgotFormState);
  const {setUserFromIdToken} = useUserState();

  const postPasswordForgotForm = useCallback(
    async () => {
      const submitBody = {
        uid: passwordForgotForm.uid,
        question: passwordForgotForm.question,
        answer: passwordForgotForm.answer,
      }

      const url = 'https://ls4oh0hgrb.execute-api.ap-northeast-1.amazonaws.com/prd/api/user/secret/confirm';
      const method = 'POST';
      const headers = {
        'Authorization': getCookie('Accesstoken'),
      };
      const body = JSON.stringify(submitBody);
  
      const res = await fetch(url, {method, headers, body})
      const data = await res.json();

      if (!res.ok) {
        return false;
      }

      setUserFromIdToken(data['id_token']);
      setCookie(data['id_token'], data['access_token']);

      return data;
    },
    [passwordForgotForm]
  );

  return {
    passwordForgotForm: passwordForgotForm,
    setPasswordForgotForm: setPasswordForgotForm,
    postPasswordForgotForm,
  };
};
