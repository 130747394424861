import { useCallback } from 'react';
import { atom, useRecoilState } from 'recoil';
import {useUserState} from '../hooks/useUserState';
import {setCookie} from '../utils/cookie';

export const passwordSecureQuestionForgotFormState = atom({
  key: 'passwordSecureQuestionForgotFormState',
  default: {
    uid: '',
    school: '',
    grade: '',
    class: '',
    name: '',
    errorMsg: ''
  },
});

export const usePasswordSecureQuestionForgotFormState = () => {
  const [passwordSecureQuestionForgotForm, setPasswordSecureQuestionForgotForm] = useRecoilState(passwordSecureQuestionForgotFormState);
  const {setUserFromIdToken} = useUserState();

  const postPasswordSecureQuestionForgotForm = useCallback(
    async () => {
      const submitBody = {}
      const url = 'https://slack.com/api/chat.postMessage?token=xoxb-528516457938-1613740285840-3f1xNjWXPi1QwuGY5156txJi&channel=%2304-01-suita&text=' +
         passwordSecureQuestionForgotForm.school +
         ' ' +
          passwordSecureQuestionForgotForm.grade +
         ' ' +
          passwordSecureQuestionForgotForm.class +
         ' ' +
          passwordSecureQuestionForgotForm.name +
         '(ID:' +
          passwordSecureQuestionForgotForm.uid +
         ') が、秘密の質問を忘れてしまいました。パスワードを再発行してください。';
      const method = 'POST';
      const headers = {
      };
      const body = JSON.stringify(submitBody);
  
      const res = await fetch(url, {method, headers, body})
      const data = await res.json();

      if (!res.ok) {
        return false;
      }

      setUserFromIdToken(data['id_token']);
      setCookie(data['id_token'], data['access_token']);

      return data;
    },
    [passwordSecureQuestionForgotForm]
  );

  return {
    passwordSecureQuestionForgotForm: passwordSecureQuestionForgotForm,
    setPasswordSecureQuestionForgotForm: setPasswordSecureQuestionForgotForm,
    postPasswordSecureQuestionForgotForm,
  };
};
