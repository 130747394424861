import React, {useEffect} from 'react';
import '../../../App.css';
import {useUserState} from '../../../hooks/useUserState';
import {useStudentConsultFormState} from '../../../hooks/useStudentConsultFormState';
import {useNavigate} from 'react-router-dom';
import {StudentNavigationBar} from '../../../components/navi/studentNavigationBar';

const CURRENT_PAGE_NUM = 5

const StudentConsultDetalPage = () => {
  const navigate = useNavigate();
  const {user} = useUserState();
  const {studentConsultForm, setStudentConsultFormState} = useStudentConsultFormState();

  useEffect(() => {
    if (user.ucl !== 'student') {
      navigate('/')
    }
  }, []);

  const handleChange = (event) => {
    setStudentConsultFormState({
      ...studentConsultForm,
      [event.target.name]: event.target.value
    });
  }

  const handlePrev = (event) => {
    if (CURRENT_PAGE_NUM - 1 > 0) {
      navigate('/student/consult/' + String(CURRENT_PAGE_NUM - 1))
      setStudentConsultFormState({
        ...studentConsultForm,
        errorMessage: '',
      });
    } else {
      setStudentConsultFormState({
        ...studentConsultForm,
        errorMessage: '',
      });
      navigate('/student')
    }
  }

  const handleNext = (event) => {
    setStudentConsultFormState({
      ...studentConsultForm,
      errorMessage: '',
    });

    navigate('/student/consult/' + String(CURRENT_PAGE_NUM + 1))
  }

  return (
    <div className="wrapper">
      <div className='uk-width-1-1 uk-flex uk-flex-middle'>
        <StudentNavigationBar disabledToTop />
        <div className="uk-width-1-2@l uk-position-top uk-container uk-animation-fade">
          <h1 className="uk-text-center uk-margin-large-top uk-margin-large-bottom">関わっている人は？</h1>
          <div className="uk-panel uk-width-expand">
            <form className="uk-width-1-1 uk-form-horizontal">
              <div className="uk-margin-bottom">
                <label className="uk-form-label">相手はですか？</label>
                <div className="uk-form-controls">
                  <input className="uk-input uk-width-1-1" id="opponent_name" type="text" placeholder="何人でも書けます" value={studentConsultForm.opponentName} name="opponentName" onChange={handleChange} />
                </div>
              </div>
              <div className="uk-margin-bottom">
                <div className="uk-form-label">知っている人はいますか？</div>
                <div className="uk-form-controls">
                  <input className="uk-input" id="observer_name" type="text" placeholder="何人でも書けます" value={studentConsultForm.observerName} name="observerName" onChange={handleChange} />
                </div>
              </div>
              <div>
                <div className="uk-form-label">詳しい内容があれば書いてください。</div>
                <div className="uk-form-controls">
                  <textarea className="uk-textarea textarea_fixed" id="detail" rows="5" maxLength="500" placeholder="500文字まで書けます" name="detail" onChange={handleChange}></textarea>
                  <p className="uk-text-small uk-margin-remove-top">対応の希望があれば、ここに書いてください。「この事を知らせたいだけ」「見守っていてほしい」など</p>
                </div>
              </div>
              <div className="uk-margin-large-top uk-margin-bottom">
                <p className="uk-text-meta uk-text-center">
                すべて書かなくても、次に進むことができます。
                </p>
              </div>
            </form>
          </div>
          <div className="uk-child-width-1-2 uk-margin-large-top" uk-grid="true">
            <div>
              <button className="uk-button uk-button-primary uk-width-small uk-margin-top uk-align-right" onClick={handlePrev}><span uk-icon="triangle-left"></span>もどる</button>
            </div>
            <div>
              <button className="uk-button uk-button-primary uk-width-small uk-margin-top uk-align-left" onClick={handleNext}>つぎへ<span uk-icon="triangle-right"></span></button>            
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentConsultDetalPage;
