import React from 'react';
import '../../App.css';
import {useNavigate} from 'react-router-dom';
import {useMessagesState} from '../../hooks/useMessagesState';
import {useMessageSendFormState} from '../../hooks/useMessageSendFormState';
import moment from 'moment';

export const StudentSendMessage = () => {
  const navigate = useNavigate();
  const {messages, fetchMessages} = useMessagesState();
  const {messageSendForm, setMessageSendForm, postMessageSendForm, clearMessageSendForm} = useMessageSendFormState();

  const handleChange = (event) => {
    setMessageSendForm({
      ...messageSendForm,
      [event.target.name]: event.target.value
    });
  }

  const handleMessageSend = async () => {
    if (messageSendForm.message === '') {
      setMessageSendForm({
        ...messageSendForm,
        errorMessage: 'メッセージを入力してください。'
      });
      return;
    }

    const res = await postMessageSendForm();

    if (res === false) {
      setMessageSendForm({
        ...messageSendForm,
        errorMessage: '送信に失敗しました。'
      });
      return;
    }

    clearMessageSendForm();
    fetchMessages();
  }

  return (
    <>
      <div className="uk-flex-top" id="modal-message" uk-modal="true">
        <div className="uk-modal-dialog uk-margin-auto-vertical uk-modal-body">
          <p className="uk-text-left uk-text-meta">
            ここからメッセージを送ると、教育委員会の担当者に届きます。
          </p>
          <div id="studentMessageList" className="uk-panel uk-panel-scrollable uk-height-large">  
            <ul className="uk-list uk-list-divider">
              {messages.data.map((message, i) => (
                <li key={`student-message-li-${i}`}>
                  <p className="uk-text-small uk-text-bold uk-margin-remove-bottom">{message.send_user === true ? 'あなた' : '教育委員会からの返信'}</p>
                  <p className="uk-margin-remove-top uk-margin-remove-bottom messageBox">{message.talk}</p>
                  <p className="uk-margin-remove-top uk-text-small uk-text-muted uk-text-right">{moment(message.created_at).format('YYYY/MM/DD HH:mm')}</p>
                </li>
              ))}
            </ul>
          </div>
          <div className="uk-inline uk-width-1-1 uk-margin-top">
            <textarea id="talkChild" className="uk-textarea textarea_fixed" rows="3" placeholder="メッセージを書いてください" name="message" value={messageSendForm.message} onChange={handleChange}></textarea>
          </div>
          {messageSendForm.errorMessage && (
            <div className='uk-alert-danger uk-margin-top uk-margin-remove-bottom uk-align-center uk-padding-small' uk-alert="true">
              <p className="uk-margin-remove">{messageSendForm.errorMessage}</p>
            </div>
          )}
          <div className="uk-width-1-1 uk-margin-top uk-text-right">
            <button className="uk-button uk-button-danger" id="sendChild" onClick={handleMessageSend}>メッセージを送る</button>
            <button className="uk-button uk-hidden" id="modal-error" type="button" uk-toggle="target: #modal-error"></button>
          </div>
          <button className="uk-modal-close-default" type="button" uk-close="true"></button>
        </div>
      </div>
    </>
  );
};
