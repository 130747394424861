import { useCallback } from 'react';
import { atom, useRecoilState } from 'recoil';
import {getCookie} from '../utils/cookie';

export const usersState = atom({
  key: 'usersState',
  default: {
    currentPage: '1',
    searchSchool: '',
    searchStatus: '',
    data: []
  },
});

export const useUsersState = () => {
  const [users, setUsers] = useRecoilState(usersState);

  const fetchUsers = useCallback(
    async (status = null) => {
      const query = status !== null ? `?status=${status}` : '';
      const url = 'https://4rhuaomwqc.execute-api.ap-northeast-1.amazonaws.com/v1/api/users' + query;
      const method = 'GET';
      const headers = {
        'Authorization': getCookie('Authorization'),
        'accesstoken': getCookie('Accesstoken')
      };

      const res = await fetch(url, {method, headers});
      const data = await res.json();

      if (!res.ok) {
        return false;
      }

      setUsers({data: data['students']})

      return data;
    },
    []
  );

  return {
    users: users,
    setUsers: setUsers,
    fetchUsers,
  };
};
