import { useCallback } from 'react';
import { atom, useRecoilState } from 'recoil';
import {getCookie} from '../utils/cookie';

export const userRegisterFormState = atom({
  key: 'userRegisterFormState',
  default: {
    email: '',
    password: '',
    area: '',
    school: '',
    grade: '',
    role: '',
    errorMessage: ''
  },
});

export const useUserRegisterFormState = () => {
  const [userRegisterForm, setUserRegisterForm] = useRecoilState(userRegisterFormState);

  const postUserRegisterForm = useCallback(
    async () => {
    const method = 'POST';
    const headers = {
      'Authorization': getCookie('Authorization'),
      'accesstoken': getCookie('Accesstoken')
    };

    const submitBody = {
      username: userRegisterForm.email,
      password: userRegisterForm.password,
      school: userRegisterForm.school,
      grade: '',
      area: userRegisterForm.area,
      role: userRegisterForm.role
    };
    const url = 'https://4rhuaomwqc.execute-api.ap-northeast-1.amazonaws.com/v1/api/auth/signup';
    const res = await fetch(url, {method, headers, body: JSON.stringify(submitBody)})
    const data = await res.json();
    if (!res.ok) {
      return false;
    }

      return data;
    },
    [userRegisterForm]
  );

  return {
    userRegisterForm: userRegisterForm,
    setUserRegisterForm: setUserRegisterForm,
    postUserRegisterForm,
  };
};
