import React, {useEffect, useState} from 'react';
import '../App.css';
import {useLoadingState} from '../hooks/useLoadingState';
import {useMessagesState} from '../hooks/useMessagesState';
import {useUsersState} from '../hooks/useUsersState';
import {IssueCommunicate} from '../components/modal/issueCommunicate';
import moment from 'moment';

export const MessagesList = () => {
  const {setLoading} = useLoadingState();
  const {messages, setMessages, fetchMessages} = useMessagesState();
  const {users, setUsers, fetchUsers} = useUsersState();
  const [notReplyStudents, setNotReplyStudents] = useState([]);
  const [repliedStudents, setRepliedStudents] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);

  useEffect(() => {
    const getInitialData = async () => {
      setLoading(true);
      const notReplyData = await fetchUsers('not_reply');
      setNotReplyStudents(notReplyData['students']);

      const repliedData = await fetchUsers('replied');
      setRepliedStudents(repliedData['students']);
      setLoading(false);
    };

    getInitialData();
  }, []);

  const handleChange = (event) => {
    setMessages({
      ...messages,
      [event.target.name]: event.target.value
    });
  }

  const handleSearch = async () => {
      setLoading(true);

      const res = await fetchUsers('not_reply');
      let notReplyData = res['students'] ?? [];

      // 所属での絞り込み
      if (messages.searchSchool !== '') {
        notReplyData = notReplyData.filter((stundent) => stundent.school === messages.searchSchool)
      }
      setNotReplyStudents(notReplyData);

      if (messages.searchStatus !== '2') {
        const res = await fetchUsers('replied');
        let repliedData = res['students'] ?? [];

        // 所属での絞り込み
        if (messages.searchSchool !== '') {
          repliedData = repliedData.filter((stundent) => stundent.school === messages.searchSchool)
        }
        setRepliedStudents(repliedData);
      } else {
        setRepliedStudents([]);
      }

      setLoading(false);
  }

  const handleSelectStudent = (stundent) => {
    setSelectedStudent(stundent)
  }

  return (
    <>
      <div className='uk-width-1-1 uk-position-top uk-container uk-animation-fade'>
        <h4 className="uk-text-left uk-margin-large-top">メッセージ一覧</h4>
        <div className="uk-width-1-1 uk-margin-remove-left uk-margin-remove-right" uk-grid="true">
          <div id="studentSchoolDiv" className="uk-padding-remove-left">
            <input id="searchStudentSchool" className="uk-input uk-width-medium" type="text" value={messages.searchSchool} name="searchSchool" onChange={handleChange} placeholder="所属名"/>
          </div>
          <div id="studentClosedDiv">
            <select id="searchStudentClosed" className="uk-select uk-width-medium" value={messages.searchStatus} name="searchStatus" onChange={handleChange}>
              <option value="1">返信済も含む</option>
              <option value="2">未返信のみ</option>
            </select>
          </div>
          <div>
            <button className="uk-button uk-button-primary" onClick={handleSearch}>検索</button>
            <button className="uk-button uk-hidden" id="modal-error" type="button" uk-toggle="target: #modal-error"></button>
          </div>
        </div>
        <table className="uk-table uk-table-striped uk-width-1-1 uk-table-small uk-table-hover">
          <thead>
            <tr>
              <th>組織</th>
              <th>所属</th>
              <th>最新のメッセージ</th>
              <th>相談者ID</th>
              <th>対応状況</th>
            </tr>
          </thead>
          <tbody>
            {notReplyStudents.map((stundent, i) => (
              <tr key={`messages-list1-tr-${i}`}>
                <td>{stundent.area}</td>
                <td>{stundent.school}</td>
                <td>{stundent.sent_at ? moment(stundent.sent_at).format('YYYY/MM/DD HH:mm') : '' }</td>
                <td id={i} onClick={() => handleSelectStudent(stundent)}>
                  <a href="#" uk-toggle="target: #modal-communicate">{stundent.sub.slice(-12)}</a>
                </td>
                <td><span className="uk-label uk-label-danger">未返信</span></td>
              </tr>
            ))}
            {repliedStudents.map((stundent, i) => (
              <tr key={`messages-list2-tr-${i}`}>
                <td>{stundent.area}</td>
                <td>{stundent.school}</td>
                <td>{stundent.sent_at ? moment(stundent.sent_at).format('YYYY/MM/DD HH:mm') : '' }</td>
                <td id={i} onClick={() => handleSelectStudent(stundent)}>
                  <a href="#" uk-toggle="target: #modal-communicate">{stundent.sub.slice(-12)}</a>
                </td>
                <td><span className="uk-label">返信済</span></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <IssueCommunicate selectedStudentId={selectedStudent?.sub} />
    </>
  );
};
