import { useCallback } from 'react';
import { atom, useRecoilState } from 'recoil';
import {getCookie} from '../utils/cookie';

export const passwordChangeFormState = atom({
  key: 'passwordChangeFormState',
  default: {
    password: '',
    confirmPassword: '',
    oldPassword: '',
    errorMsg: ''
  },
});

export const usePasswordChangeFormState = () => {
  const [passwordChangeForm, setPasswordChangeForm] = useRecoilState(passwordChangeFormState);

  const postPasswordChangeForm = useCallback(
    async () => {
      const submitBody = {
        previous: passwordChangeForm.oldPassword,
        propose: passwordChangeForm.password,
      }

      const url = 'https://4rhuaomwqc.execute-api.ap-northeast-1.amazonaws.com/v1/api/auth/change';
      const method = 'POST';
      const headers = {
        'Authorization': getCookie('Accesstoken'),
      };
      const body = JSON.stringify(submitBody);
  
      const res = await fetch(url, {method, headers, body})
      const data = await res.json();

      if (!res.ok) {
        return false;
      }

      return data;
    },
    [passwordChangeForm]
  );

  return {
    passwordChangeForm: passwordChangeForm,
    setPasswordChangeForm: setPasswordChangeForm,
    postPasswordChangeForm,
  };
};
