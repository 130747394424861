import React, {useState} from 'react';
import '../../App.css';

export const Pagination = ({
  data = [],
  currentPage = 1,
  limit = 10,
  handlePrevious = () => {},
  handleNext = () => {},
}) => {
  const MaxPage = () => {
    return Math.ceil(data.length / limit);
  }

  const handlePreviousPagination = () => {
    handlePrevious()
  }

  const handleNextPagination = () => {
    handleNext()
  }

  return (
    <>
      <ul className="uk-pagination uk-margin-large-bottom">
        {currentPage > 1 && (
          <li><button className="uk-button uk-button-default" onClick={handlePreviousPagination}><span className="uk-margin-small-right" uk-pagination-previous="true"></span> 前のページ</button></li>
        )}
        {currentPage < MaxPage() && (
          <li className="uk-margin-auto-left"><button className="uk-button uk-button-default" onClick={handleNextPagination}>次のページ <span className="uk-margin-small-left" uk-pagination-next="true"></span></button></li>
        )}
      </ul>
    </>
  );
};
