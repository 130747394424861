import { useCallback } from 'react';
import { atom, useRecoilState } from 'recoil';
import {getCookie} from '../utils/cookie';

export const issuesState = atom({
  key: 'issuesState',
  default: {
    currentPage: '1',
    searchSchool: '',
    searchStatus: '',
    data: []
  },
});

export const useIssuesState = () => {
  const [issues, setIssues] = useRecoilState(issuesState);

  const fetchIssues = useCallback(
    async (setState = true) => {
      const url = 'https://4rhuaomwqc.execute-api.ap-northeast-1.amazonaws.com/v1/api/issues?_=' + Date.now();
      const method = 'GET';
      const headers = {
        'Authorization': getCookie('Authorization'),
        'accesstoken': getCookie('Accesstoken')
      };

      const res = await fetch(url, {method, headers});
      const data = await res.json();

      if (!res.ok) {
        return false;
      }
      if (setState) {
        setIssues({
          ...issues,
          data: data['issues']
        })
      }

      return data;
    },
    []
  );

  return {
    issues: issues,
    setIssues: setIssues,
    fetchIssues,
  };
};
