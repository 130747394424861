import React, {useEffect, useState} from 'react';
import '../../App.css';
import {useUsersState} from '../../hooks/useUsersState';
import {getCookie} from '../../utils/cookie';

export const UserDetail = ({selectedUser}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [email, setEmail] = useState(selectedUser?.email);
  const [school, setSchool] = useState(selectedUser?.school);
  const {fetchUsers} = useUsersState();

  useEffect(() => {
    setEmail(selectedUser?.email)
    setSchool(selectedUser?.school)
  }, [selectedUser]);

  const handleChangeEmail = (event) => {
    setEmail(event.target.value)
  }

  const handleChangeSchool = (event) => {
    setSchool(event.target.value)
  }

  const handleUpdateUserInformation = async (event) => {
    if (email === '') {
      setErrorMessage('エラー: メールアドレスを入力してください。');
      return;
    }

    setErrorMessage('');

    const method = 'PUT';
    const headers = {
      'Authorization': getCookie('Authorization'),
      'accesstoken': getCookie('Accesstoken')
    };

    const submitBody = {
      school: school,
    };
    const url = 'https://4rhuaomwqc.execute-api.ap-northeast-1.amazonaws.com/v1/api/users/' + selectedUser?.sub + '/school';
    const res = await fetch(url, {method, headers, body: JSON.stringify(submitBody)})
    await res.json();
    if (!res.ok) {
      setErrorMessage('エラー: 更新に失敗しました。');
      return;
    }

    const submitEmailBody = {
      email: email,
    };   
    const urlEmail = 'https://4rhuaomwqc.execute-api.ap-northeast-1.amazonaws.com/v1/api/users/' + selectedUser?.sub + '/email';

    const resEmail = await fetch(urlEmail, {method, headers, body: JSON.stringify(submitEmailBody)})
    await resEmail.json();
    if (!resEmail.ok) {
      setErrorMessage('エラー: 更新に失敗しました。');
      return;
    }

    await fetchUsers();
    document.getElementById('userDetailClose').click();
  }

  const handleDeleteUserInformation = async (event) => {
    if (!window.confirm("削除するとユーザーの情報がすべて閲覧できなくなります。\r\n削除して問題ありませんか？")) {
      return;
    }
    const url = 'https://4rhuaomwqc.execute-api.ap-northeast-1.amazonaws.com/v1/api/users/' + selectedUser?.sub;
    const method = 'DELETE';
    const headers = {
      'Authorization': getCookie('Authorization'),
      'accesstoken': getCookie('Accesstoken')
    };

    const res = await fetch(url, {method, headers})
    await res.json();
    if (!res.ok) {
      setErrorMessage('エラー: に失敗しました。');
      return;
    }

    await fetchUsers();
    document.getElementById('userDetailClose').click();
  }

  return (
    <>
      <div className="uk-flex-top" id="modal-userDetail" uk-modal="true">
        <div className="uk-modal-dialog uk-margin-auto-vertical">
          <div className="uk-modal-header">
            <h3 className="uk-text-bold uk-text-center uk-width-1-1">ユーザ詳細</h3>
          </div>
          <div className={errorMessage ? 'uk-alert-danger uk-margin-top uk-margin-remove-bottom uk-align-center uk-padding-small' : 'uk-hidden'} uk-alert="true">
            <p className="uk-margin-remove">{errorMessage}</p>
          </div>
          <div className="uk-modal-body uk-height-1-1">
            <div className="uk-width-1-1 uk-height-1-1 uk-margin-remove">
              <div className="uk-height-1-1 uk-padding-remove">
                <form className="uk-width-1-1 uk-form-horizontal">
                  <div className="uk-margin-bottom">
                    <div className="uk-form-label">ID</div>
                    <div className="uk-form-controls">
                      <input className="uk-input uk-form-blank" type="text" value={selectedUser?.sub} />
                    </div>
                  </div>
                  <div className="uk-margin-bottom">
                    <div className="uk-form-label">組織</div>
                    <div className="uk-form-controls">
                      <input className="uk-input uk-form-blank" type="text" value={selectedUser?.area} />
                    </div>
                  </div>
                  <div className="uk-margin-bottom">
                    <div className="uk-form-label">所属</div>
                    <div className="uk-form-controls">
                      <input className="uk-input" id="updateUserSchool" type="text" value={school} name="school" onChange={handleChangeSchool} />
                    </div>
                  </div>
                  <div className="uk-margin-bottom">
                    <div className="uk-form-label">メールアドレス</div>
                    <div className="uk-form-controls">
                      <input className="uk-input" id="updateUserMail" type="text" value={email} name="email" onChange={handleChangeEmail} />
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <p className="uk-text-center uk-margin-top">
              <button className="uk-button uk-button-default uk-margin-small-right" type="button" onClick={handleUpdateUserInformation}>更新</button>
              <button className="uk-button uk-button-danger" type="button" onClick={handleDeleteUserInformation}>削除</button>
              <button className="uk-button uk-hidden" id="modal-error" type="button" uk-toggle="target: #modal-error"></button>   
            </p>
          </div>
          <button className="uk-modal-close-default" id="userDetailClose" type="button" uk-close="true"></button>
        </div>
      </div>
    </>
  );
};
